<template>
  <div>
    <div class="d-flex align-start flex-wrap ma-n1">
      <v-chip
        pill
        v-for="(item, i) in onlineRaw"
        :key="i"
        class="ma-1"
        :class="{ 'font-weight-bold': item.unread }"
        @click="() => selectChat(item, i)"
      >
        <v-avatar left :color="item.unread ? 'success' : ''">
          <v-img
            :src="
              item.picture ||
              'https://i.ibb.co/dPFwxwC/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg'
            "
          ></v-img>
        </v-avatar>
        {{ item.name }}
      </v-chip>
    </div>
    <v-card-text
      style="max-height: 75vh; overflow-y: scroll"
      ref="scroll"
      id="scroll"
    >
      <div v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else-if="messagesData === null">Inicie uma conversa</div>
      <div v-else-if="messagesData.lenght === 0">Nenhuma mensagem enviada</div>
      <div v-else v-for="(message, i) in messagesData" :key="i">
        <div v-if="message.type === 'start day'">
          <hr class="my-4" />
          <v-chip class="mx-auto d-table" color="primary">
            {{ message.sendedAt | formatDate }}
          </v-chip>
        </div>
        <div
          v-else
          class="d-flex align-center"
          style="width: 100%"
          :class="message.sender !== 'client' ? 'flex-row-reverse' : ''"
        >
          <v-avatar>
            <v-icon>{{
              message.sender === "client"
                ? "mdi-account-outline"
                : "mdi-badge-account-alert-outline"
            }}</v-icon>
          </v-avatar>
          <div class="d-flex flex-column">
            <v-chip
              :color="message.sender !== 'client' ? 'success darken-2' : ''"
              :style="
                'border-radius: 100px 100px ' +
                (message.sender !== 'client' ? '3px 100px' : '100px 3px')
              "
            >
              {{ message.message }}
            </v-chip>
            <span class="text-caption">{{
              message.sendedAt | formatDate
            }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions v-if="messagesData !== null">
      <v-btn text color="error" class="mr-4" @click="exitChat">Encerrar</v-btn>
      <v-text-field
        rounded
        outlined
        label="Digite sua mensagem"
        hide-details
        dense
        @keyup.enter="submitMessage"
        v-model="message"
      />
      <v-btn fab small color="success" class="ml-4" @click="submitMessage">
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import api from "@/api";

export default {
  name: "Chat",
  data: () => ({
    chats: {},
    online: [],
    selected: null,
    message: "",
    chatOpen: false,
    loading: false,
  }),
  props: ["socket"],
  methods: {
    selectChat(item, index) {
      this.getHistoryChat(item._id);
      this.selected = item._id;
      this.chatOpen = item.chatOpen;
    },
    getHistoryChat(client_id) {
      if (!this.chats[client_id]) {
        this.loading = true;
        api.get("/messages", { params: { client_id } }).then(({ data }) => {
          this.chats = {
            ...this.chats,
            [client_id]: data.map((a) => ({
              ...a,
              sendedAt: moment(a.sendedAt),
            })),
          };
          this.loading = false;

          this.scrollToEnd();
        });
      }
    },
    scrollToEnd(interval) {
      setTimeout(() => {
        const el = this.$refs.scroll || window.scroll;
        if (el) el.scrollTop = 1000000000 * 10000 * 1000;
      }, interval || 200);
    },
    submitMessage() {
      const payload = {
        client_id: this.selected,
        user_id: this.$store.state.user._id,
        message: this.message,
        sendedAt: moment(),
        sender: "admin",
      };
      api.post("/messages/", {
        ...payload,
        sendedAt: payload.sendedAt.format("YYYY-MM-DD HH:mm:ss Z"),
      });
      this.chats[this.selected].push(payload);
      this.message = "";

      const el = this.$refs.scroll || window.scroll;
      setTimeout(() => (el.scrollTop = 1000000000 * 10000 * 1000), 200);
    },
    exitChat() {
      this.socket.emit("exitChat", this.selected);
      this.selected = null;
    },
  },
  mounted() {
    this.socket.on("online", (data) => {
      this.online = data;
    });

    /* this.socket.on(
      "newMessage",
      (m) =>
        console.log(m) ||
        this.chats[m.client_id].push({ ...m, sendedAt: moment(m.sendedAt) })
    );
*/
    this.socket.on("newMessage", async (m) => {
      const el = this.$refs.scroll || window.scroll;
      await this.getHistoryChat(m.client_id);
      if (!this.selected) this.selected = m.client_id;
      if (!el)
        return this.chats[m.client_id].push({
          ...m,
          sendedAt: moment(m.sendedAt),
        });
      const scrollMax = el.scrollHeight - el.offsetHeight;
      console.log(scrollMax - 5, el.scrollTop);
      const fixOnEnd = scrollMax - 5 < el.scrollTop;
      console.log(m.client_id);
      this.chats[m.client_id].push({ ...m, sendedAt: moment(m.sendedAt) });
      console.log(this.chats[m.client_id]);
      if (fixOnEnd)
        setTimeout(() => (el.scrollTop = 1000000000 * 10000 * 1000), 200);
    });
  },
  computed: {
    onlineRaw() {
      return this.online.map((a) => ({
        ...a,
        unread: this.chats[a._id]
          ? this.chats[a._id].length
            ? this.chats[a._id][this.chats[a._id].length - 1].sender ===
              "client"
            : false
          : false,
      }));
    },
    messagesData() {
      if (
        !this.selected ||
        !this.online.find((a) => a._id === this.selected) ||
        !this.chats[this.selected]
      )
        return null;

      const messages = this.chats[this.selected] || [];
      return messages.reduce((response, item, i) => {
        const last = messages[i - 1];

        console.log("last", last, i);

        if (
          last &&
          moment(last.sendedAt)
            .startOf("day")
            .diff(moment(item.sendedAt).startOf("day")) !== 0
        ) {
          response.push({
            type: "start day",
            label: "começa novo dia",
            sendedAt: item.sendedAt.startOf("day"),
          });
        }
        response.push(item);

        return response;
      }, []);
    },
  },
  filters: {
    formatDate(v) {
      if (!v) return;
      return (v._isAMomentObject ? v : moment(v)).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
