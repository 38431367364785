<template>
  <div>
    <h3 class="text-h3">Página: Leilão</h3>
    <v-card class="mt-4">
      <v-card-text>
        <div
          style="
            border: solid 1px rgba(0, 0, 0, 0.38);
            border-radius: 4px;
            padding: 4px;
          "
        >
          <Vueditor ref="editor1" style="height: 600px">{{
            liveText
          }}</Vueditor>
        </div>
        <v-text-field
          label="Youtube ID"
          outlined
          v-model="youtubeChannelId"
          class="mt-10"
        />

        <v-row v-for="(item, i) in phones" :key="i" class="d-flex align-center">
          <v-col cols="5">
            <v-text-field
              outlined
              label="Telefone/Celular"
              v-model="phones[i].phone"
            />
          </v-col>
          <v-col cols="5">
            <v-select
              outlined
              v-model="phones[i].type"
              :items="[
                { text: 'Informações', value: 'info' },
                { text: 'Lance', value: 'bid' },
              ]"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              block
              color="error"
              text
              @click="phones = phones.filter((_a, ii) => ii !== i)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
              <span>Excluir</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          class="d-table mx-auto my-4"
          @click="() => this.phones.push({ phone: '', type: 'info' })"
        >
          <v-icon>mdi-plus</v-icon>
          <span>Inserir</span>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="submit">
          <v-icon>mdi-content-save-outline</v-icon>
          <span>Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "ContentSobre",
  data: () => ({
    editing: null,
    liveText: "",
    phones: [],
    youtubeChannelId: "",
  }),
  mounted() {
    this.get();
  },
  methods: {
    async submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        await api.patch("/config/set", {
          phones: await Promise.all(this.phones),
          youtubeChannelId: this.youtubeChannelId,
          liveText: this.$refs.editor1.getContent(), //this.liveText,
        });

        alert("Dados salvos com sucesso!");
        this.get();
      } catch (err) {
        console.error(err);
      }
      loader.hide();
    },
    async get() {
      const { data } = await api.get("/config/get", {
        params: {
          keys: "phones,youtubeChannelId,liveText",
        },
      });
      this.phones = (data.phones || []).filter((a) => a.phone);
      this.youtubeChannelId = data.youtubeChannelId;
      this.liveText = data.liveText;
      this.$refs.editor1.setContent(this.liveText);
    },
  },
};
</script>

<style></style>
