






export default {
  name: "App",
};
