<template>
  <v-card color="white">
    <form @submit.prevent="submit">
      <v-card-text>
        <p>
          Faça envio de notificações em massa para todos os usuários da
          plataforma
        </p>
        <v-row align="center">
          <v-col cols="6">
            <v-text-field
              label="Título"
              placeholder="Venha assistir..."
              :error-messages="errors.title"
              v-model="title"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Link"
              placeholder="http://url.com"
              v-model="url"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Mensagem"
              placeholder=""
              :error-messages="errors.body"
              v-model="body"
            />
          </v-col>
          <v-col cols="4">
            <v-btn block color="primary" type="submit">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </form>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "PushCard",
  data: () => ({
    title: "",
    url: "",
    body: "",

    errors: {
      title: [],
      body: [],
    },
  }),
  methods: {
    validate() {
      if (!this.title) this.errors.title = ["Digite o título"];
      if (!this.body) this.errors.body = ["Digite a mensagem"];
    },
    submit() {
      this.validate();
      if (Object.values(this.errors).find((a) => a.length > 0)) return;

      api.post("/notification/send", {
        title: this.title,
        body: this.body,
        action: this.url
          ? this.url.startsWith("http")
            ? `/to?url=${encodeURIComponent(this.url)}`
            : this.url
          : undefined,
      });
    },
  },
  watch: {
    title() {
      this.errors.title = [];
    },
    body() {
      this.errors.body = [];
    },
  },
};
</script>

<style></style>
