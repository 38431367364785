<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>Integrações</span>
      <v-btn text @click="add">
        <v-icon>mdi-plus</v-icon>
        <span>INSERIR</span>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert color="info" v-if="supportFS">
        <div style="color: white">
          <h5>Nova integração do VMix</h5>
          <p class="text-caption">
            Use o botão abaixo para participar do teste beta de uma nova
            integração com o VMix. Essa nova integração ira se comunicar com o
            software atraves de um arquivo JSON.
          </p>
          <v-btn @click="newVMixActive" :disabled="newVMix">{{
            newVMix ? "Ativo" : "Ativar"
          }}</v-btn>
        </div>
      </v-alert>
      <v-list>
        <v-list-item v-for="(item, i) in rows" :key="i">
          <img
            :src="require('../../../assets/vmix.png')"
            style="object-fit: contain; max-height: 28px"
            class="mr-4"
          />
          <v-switch v-model="item.enable" />
          <v-list-item-content>
            <v-list-item-title> {{ item.name }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.ip }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex flex-row">
            <v-btn icon @click="() => edit(i)">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="() => deleteRow(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-form @submit.prevent="submit">
      <v-dialog v-model="modalEdit" max-width="500px">
        <v-card v-if="(editing || editing === 0) && rows[editing].software">
          <v-card-title>
            Editar integração: {{ rows[editing].software }} -
            {{ rows[editing].name }}
          </v-card-title>
          <v-card-text>
            <v-select
              disabled
              label="Software"
              v-model="rows[editing].software"
              outlined
              :items="['vMix']"
            />
            <v-text-field
              label="Identificador"
              v-model="rows[editing].name"
              outlined
            />
            <v-text-field label="IP" v-model="rows[editing].ip" outlined />
            <v-text-field
              label="Input - Em lote"
              v-model="rows[editing].INPUT_LOTE"
              outlined
            />
            <v-text-field
              label="Input - Em espera"
              v-model="rows[editing].INPUT_ESPERA"
              outlined
            />
            <v-text-field
              label="Texto - Título Lote"
              v-model="rows[editing].TEXTO_NOME"
              outlined
            />
            <v-text-field
              label="Texto - Número do Lote"
              v-model="rows[editing].TEXTO_NUMERO"
              outlined
            />
            <v-text-field
              label="Texto - Vendedor"
              v-model="rows[editing].TEXTO_VENDEDOR"
              outlined
            />
            <v-text-field
              label="Texto - Pagamento"
              v-model="rows[editing].TEXTO_PAGAMENTO"
              outlined
            />
            <v-text-field
              label="Texto - Idade"
              v-model="rows[editing].TEXTO_IDADE"
              outlined
            />
            <v-text-field
              label="Texto - Peso"
              v-model="rows[editing].TEXTO_PESO"
              outlined
            />
            <v-text-field
              label="Texto - Valor"
              v-model="rows[editing].TEXTO_VALOR"
              outlined
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="() => (modalEdit = false)">Cancelar</v-btn>
            <v-btn color="primary" @click="submit">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "VMix",
  data: () => ({
    modalEdit: false,
    editing: null,
    original: null,
    rows: [],
    newVMix: !!window.newVMix,
    supportFS: !!window.showSaveFilePicker,
  }),
  async created() {
    const {
      data: { thirdPartyStream },
    } = await api.get("/config/get", {
      params: { keys: "thirdPartyStream" },
    });
    this.rows =
      (thirdPartyStream || []).map((a) => ({ ...a, enable: false })) || [];
  },
  mounted() {
    if (window.a) clearInterval(window.a);
    window.a = setInterval(() => {
      this.updateGC();
    }, 10000);
  },
  props: ["lot", "current"],
  methods: {
    save() {
      api.patch("/config/set", {
        thirdPartyStream: this.rows,
      });
    },
    submit() {
      this.save();
      this.editing = null;
      this.modalEdit = null;
      this.$swal("Salvo com sucesso!", "", "success");
    },
    edit(index) {
      this.original = JSON.parse(JSON.stringify(this.rows[index]));
      this.modalEdit = true;
      this.editing = index;
    },
    cancel() {
      if (this.modalEdit) return;
      if (this.original)
        this.rows[this.editing] = JSON.parse(JSON.stringify(this.original));
      else this.rows = this.rows.filter((_a, i) => i !== this.editing);
      this.editing = null;
    },
    add() {
      this.editing =
        this.rows.push({
          enable: false,
          software: "vMix",
          name: "",
          ip: "127.0.0.1",
          INPUT_LOTE: "1",
          INPUT_ESPERA: "2",
          TEXTO_NOME: "TITULO_LOTE",
          TEXTO_NUMERO: "NUMERO_LOTE",
          TEXTO_VENDEDOR: "VENDEDOR_LOTE",
          TEXTO_PAGAMENTO: "PAGAMENTO_LOTE",
          TEXTO_IDADE: "IDADE_LOTE",
          TEXTO_PESO: "PESO_LOTE",
          TEXTO_VALOR: "VALOR_LOTE",
        }) - 1;
      this.modalEdit = true;
    },
    deleteRow(index) {
      this.rows = this.rows.filter((_a, i) => i !== index);
      this.save();
    },
    toggleGC() {
      this.rows.forEach((item) => {
        if (!item.enable) return;
        this.sendRequest(
          `http://${item.ip}:8088/API/?Function=OverlayInput4&Duration=1000&Input=` +
            (window.inLot ? item.INPUT_LOTE : item.INPUT_ESPERA)
        );
      });
    },
    sendRequest(url) {
      console.log("oi");
      const el = document.createElement("img");
      el.src = url;
      console.log(el);
      document.body.appendChild(el);

      setTimeout(() => el.remove(), 1500);
    },
    updateGC() {
      const lot = this.lot;
      if (window.inLot != !!lot.lot) {
        window.inLot = !!lot.lot;
        console.log(
          window.inLot ? "EXIBE DADOS DO LEILÃO" : "MOSTRA TELEFONES"
        );
        try {
          this.toggleGC();
        } catch (err) {
          console.error(err);
        }
      }

      (async () => {
        if (!window.newVMix) return;
        const writableStream = await window.newVMix.createWritable();
        await writableStream.write(
          JSON.stringify({
            TEXTO_NOME: this.lot.label,
            TEXTO_NUMERO: this.lot.lot,
            TEXTO_VENDEDOR: this.lot.details.location,
            TEXTO_PAGAMENTO: this.lot.payment,
            TEXTO_IDADE: this.lot.details.age,
            TEXTO_PESO: this.lot.details.weight,
            TEXTO_VALOR:
              this.current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
              ",00",
          })
        );
        await writableStream.close();
      })();

      [
        ["TEXTO_NOME", this.lot.label],
        ["TEXTO_NUMERO", this.lot.lot],
        ["TEXTO_VENDEDOR", this.lot.details.location],
        ["TEXTO_PAGAMENTO", this.lot.payment],
        ["TEXTO_IDADE", this.lot.details.age],
        ["TEXTO_PESO", this.lot.details.weight],
        [
          "TEXTO_VALOR",
          this.current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",00",
        ],
      ].forEach(([text, value]) => {
        this.rows.forEach((item) => {
          if (!item.enable) return;
          this.sendRequest(
            `http://${item.ip}:8088/API/?Function=SetText&Input=${
              item.INPUT_LOTE
            }&SelectedName=${item[text]}&Value=${encodeURIComponent(
              value || ""
            )}`
          );
        });
      });
    },
    async newVMixActive() {
      this.newVMix = await window.showSaveFilePicker({
        suggestedName: "VMIX_INFO.json",
      });
      window.newVMix = this.newVMix;
    },
  },
  watch: {
    modalEdit() {
      this.cancel();
    },
    lot() {
      this.updateGC();
    },
  },
};
</script>

<style></style>
