<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <h3 class="text-h3">Contatos</h3>
        <p>Contatos realizados através da tela de contato</p>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="contacts"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click="() => deleteRow(item._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "contacts",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Telefone", value: "phone" },
      { text: "Assunto", value: "subject" },
      { text: "Mensagem", value: "message" },
      { text: "Ações", value: "actions", align: "end" },
    ],
    contacts: [],
  }),

  created() {
    this.get();
  },

  methods: {
    get() {
      api
        .get("/contact")
        .then(({ data }) => (this.contacts = data))
        .catch((e) => {
          this.$swal(
            "Ops...",
            "Ocorreu um problema ao buscar os dados.",
            "error"
          );
          this.$router.push("/");
        });
    },
    deleteRow(id) {
      this.$swal({
        title: "Tem certeza?",
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Desistir`,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.contacts = this.contacts.filter((a) => a._id !== id);
          api.delete("/contact/" + id);
        }
      });
    },
  },
};
</script>

<style></style>
