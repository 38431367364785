<template>
  <div class="gray d-flex align-center justify-center" style="height: 100%">
    <div style="max-width: 450px">
      <v-img width="80%" :src="$p.logo" class="mx-auto mb-4" contain />
      <v-card max-width="450" elevation="5">
        <v-app-bar color="primary" dark max-width="450">
          <span class="font-weight-medium">Seja bem vindo</span>
        </v-app-bar>
        <v-card-text>
          <v-form @submit.prevent="submit">
            <p>Faça login para acessar o painel de gerenciamento</p>
            <v-text-field label="E-mail" v-model="email" />
            <v-text-field label="Senha" type="password" v-model="password" />
            <v-btn
              @click="submit"
              type="submit"
              color="primary"
              block
              class="mt-3"
              :disabled="loading"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from "@/api.ts";

export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    loading: false,
  }),
  methods: {
    async submit() {
      if (this.loading) return;
      this.loading = true;
      try {
        const { data } = await api.post("/auth/login", {
          username: this.email,
          password: this.password,
        });
        if (data.access_token) {
          this.loading = false;
          localStorage.tk = data.access_token;
          this.$router.push("/");
        } else new Error();
      } catch (err) {
        this.loading = false;
        console.error(err);
        this.$swal(
          "Dados incorretos",
          "Confira se os dados inseridos estão corretos.",
          "error"
        );
      }
    },
  },
};
</script>

<style></style>
