<template>
  <div>
    <div class="d-flex items-center my-4">
      <div class="d-flex flex-column justify-center align-center mr-4">
        <div class="text-caption">ONLINE</div>
        <div class="text-h4">{{ online.length }}</div>
      </div>
      <v-hover v-slot="{ hover }">
        <marquee-text
          :duration="online.length * 2"
          :paused="hover || online.length <= 4"
          :repeat="hover || online.length <= 4 ? 1 : 2"
          class="my-5"
          style="flex: 1"
        >
          <div class="d-flex">
            <v-list-item v-for="(item, i) in online" :key="i">
              <v-list-item-avatar>
                <img
                  :src="
                    item.picture ||
                    'https://i.ibb.co/dPFwxwC/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg'
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle class="success--text"
                  >ONLINE</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </marquee-text>
      </v-hover>
    </div>
    <views />
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
import Views from "../Views.vue";

export default {
  name: "Online",
  components: { MarqueeText, Views },
  data: () => ({
    online: [],
  }),
  props: ["socket"],
  mounted() {
    this.socket.on("online", (data) => {
      this.online = data;
    });
  },
};
</script>

<style></style>
