



















































































































import CardDashboard from "@/components/CardDashboard.vue";
import PushCard from "@/components/PushCard.vue";
import api from "@/api";
import moment from "moment";
import Views from "../components/Views.vue";

export default {
  name: "Home",
  components: { CardDashboard, PushCard, Views },
  data: () => ({
    clientsCount: "---",
    contactsCount: "---",
    clients: [],
    nextAuction: null,
    isLiving: null,

    live: {
      type: "Youtube",
      value: "",
    },
  }),
  methods: {
    async getClientsCount() {
      try {
        const { data } = await api.get("/client/count");
        this.clientsCount = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getContactsCount() {
      try {
        const { data } = await api.get("/contact/count");
        this.contactsCount = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getClients() {
      try {
        const { data } = await api.get("/client", { params: { limit: 10 } });
        this.clients = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getNextAuction() {
      try {
        const { data } = await api.get("/auction", {
          params: { limit: 1, next: true, project: this.$p.project },
        });
        this.nextAuction = data[0];
      } catch (err) {
        console.error(err);
      }
    },
    async getLiveStatus() {
      try {
        const { data } = await api.get("/config/live_status");
        this.isLiving = data && data.value && data.value === "ON";
      } catch (err) {
        console.error(err);
      }

      const { data } = await api.get("/config/get", {
        params: {
          keys: "live",
        },
      });
      if (data.live) this.live = data.live;
    },
    async save() {
      const value = this.live.value;
      if (this.live.type == "Youtube") {
        try {
          this.live.value = this.live.value
            .match(/((embed\/|watch\?v=))([a-zA-Z0-9]{11})/)
            .find((a) => a.length === 11);
        } catch (err) {
          console.error(err, this.live.value);
        }
      }
      await api.patch("/config/set", {
        live: this.live,
      });
      this.$swal("Salvo com sucesso!", "", "success");
    },
  },
  created() {
    if (this.$store.state.user) {
      const abilitys = this.$store.state.user.abilitys.filter((a) =>
        this.$p.modules.includes(a)
      );
      if (abilitys && abilitys.length === 1) {
        if (abilitys[0] === "painel-lance") return this.$router.push("/lance");
      }
    }

    this.getClientsCount();
    this.getContactsCount();
    this.getClients();
    this.getNextAuction();
    this.getLiveStatus();
  },
  filters: {
    date(v) {
      return moment(v).utcOffset(v).format("DD/MM/YYYY");
    },
  },
  watch: {
    isLiving(newValue, oldValue) {
      if (oldValue === null) return;
      api.patch("/config/live_status", {
        value: newValue ? "ON" : "OFF",
      });
    },
    "$store.state.user"({ abilitys }) {
      abilitys = abilitys.filter((a) => this.$p.modules.includes(a));
      if (abilitys && abilitys.length === 1) {
        if (abilitys[0] === "painel-lance") return this.$router.push("/lance");
      }
      if (abilitys && abilitys.length === 1) {
        if (abilitys[0] === "painel-stream")
          return this.$router.push("/stream");
      }
    },
  },
};
