<template>
  <div>
    <h3 class="text-h3">Página: Cadastro</h3>
    <v-card class="mt-4">
      <v-card-text>
        <h3 class="text-caption">Página Cadastro</h3>
        <div
          style="
            border: solid 1px rgba(0, 0, 0, 0.38);
            border-radius: 4px;
            padding: 4px;
          "
        >
          <tiptap-vuetify
            v-model="textRegister"
            :extensions="extensions"
            icon="mdi"
            :cardProps="{ elevation: 0 }"
          />
        </div>

        <h3 class="mt-8">
          Informe as informações para o formulário de cadastro
        </h3>
        <v-checkbox
          v-for="(item, i) in sectionsOptions"
          :key="i"
          v-model="registerSections"
          :label="item"
          :value="item"
          hide-details
        ></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="submit">
          <v-icon>mdi-content-save-outline</v-icon>
          <span>Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
} from "tiptap-vuetify";

export default {
  name: "ContentSobre",
  components: { TiptapVuetify },
  data: () => ({
    sectionsOptions: [
      "Foto de perfil",
      "Dados pessoais",
      "Dados de correspondência",
      "Dados de contato",
      "Dados de propriedade",
      "Dados Bancários",
      "Preferências",
      "Referências",
      "Arquivo - Certidão de Ônus",
      "Arquivo - RG ou CNH",
      "Arquivo - Comprovante de Residência",
      "Arquivo - Imposto de Renda",
      "Arquivo - Inscrição Estadual",
      "Arquivo - Escritura da Fazenda",
    ],

    textRegister: "",
    registerSections: [],

    extensions: [
      Image,
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  created() {
    this.get();
  },
  methods: {
    async submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        await api.patch("/config/set", {
          textRegister: this.textRegister,
          registerSections: this.registerSections,
        });

        alert("Dados salvos com sucesso!");
        this.get();
      } catch (err) {
        console.error(err);
      }
      loader.hide();
    },
    async get() {
      const { data } = await api.get("/config/get", {
        params: {
          keys: "textRegister,registerSections",
        },
      });
      this.textRegister = data.textRegister || "";
      this.registerSections = data.registerSections || [];
    },
  },
};
</script>

<style></style>
