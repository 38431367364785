<template>
  <v-dialog v-model="dialog" scrollable max-width="1200px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn block color="success" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-microsoft-excel</v-icon>
        <span>Carregar excel</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Importar lotes</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-file-input truncate-length="15" @change="inputFile"></v-file-input>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nº</th>
                <th class="text-left">Título</th>
                <th class="text-left">Pagamento</th>
                <th class="text-left">Localidade/Vendedor</th>
                <th class="text-left">Idade</th>
                <th class="text-left">Peso</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in preview" :key="i">
                <td>{{ item.lot }}</td>
                <td>{{ item.label }}</td>
                <td>{{ item.payment }}</td>
                <td>{{ item.details.location }}</td>
                <td>{{ item.details.age }}</td>
                <td>{{ item.details.weight }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Fechar
        </v-btn>
        <v-btn
          :disabled="preview.length === 0"
          color="blue darken-1"
          text
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import readXlsxFile from "read-excel-file";
import api from "@/api";

export default {
  name: "LoadExcel",
  data: () => ({ dialog: false, preview: [] }),
  methods: {
    inputFile(file) {
      readXlsxFile(file).then((rows) => {
        this.preview = rows
          .filter((a) => a[0])
          .map((a) => ({
            lot: a[0],
            label: a[1]
              ? (
                  a[1]
                    .trim()
                    .replace(/\s+/g, " ")
                    .replace(/novilho/gi, "garrote")
                    .replace(/ nelorad/gi, " anelorad")
                    .replace(
                      /([0-9]+) (bezerr[a|o]|garrote|novilha|vaca|boi|anelorad[a|o]|mesti[c|ç][a|o]|cruzad[o|a])s?/gi,
                      (_, quantity, name) =>
                        quantity + " " + name + (quantity > 1 ? "s" : "")
                    ) + " "
                )
                  .replace(
                    /s anelorad([a|o])([^s])?/gi,
                    (_, a, b) => "s anelorad" + a + "s" + b
                  )
                  .toUpperCase()
              : "",
            payment: a[6],
            details: {
              age: a[2]
                ? /[A-Za-z]/g.test(`${a[2]}`)
                  ? a[2]
                  : a[2] + "M"
                : "",
              weight: a[3],
              location: a[4],
            },
          }));
      });
    },
    submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      api
        .post("/auction/list_lot", this.preview)
        .then((a) => {
          loader.hide();
          this.$swal(
            "Salvo com sucesso!",
            "Dados importado com sucesso!",
            "success"
          );
          this.dialog = false;
        })
        .catch((err) => {
          console.error(err);
          this.$swal(
            "Ops!",
            "Ocorreu um erro! Contate o administrador",
            "error"
          );
          loader.hide();
        });
    },
  },
};
</script>

<style></style>
