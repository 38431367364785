<template>
  <div>
    <h3 class="text-h3">Painel de transmissão</h3>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-4">
          <v-row align="center">
            <v-col xs="12" md="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Idade</v-list-item-title>
                  <v-list-item-subtitle>{{
                    lot.details.age
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Peso</v-list-item-title>
                  <v-list-item-subtitle>{{
                    lot.details.weight
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    lot.details.location
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6">
              <div class="text-center">
                <h1 class="text-h6 text-md-h3">LOTE {{ lot.lot }}</h1>
                <div class="mb-5">{{ lot.label }}</div>
                <span>Valor por animal</span>
                <h1 class="text-h5 text-md-h1">
                  {{ current | brl }}
                </h1>
                <span><b>Pagamento:</b> {{ lot.payment }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-10">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" md="7">
                <v-form @submit.prevent="submitForm">
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                        v-model="freeForm"
                        hide-details
                        label="Fomulário livre"
                        v-if="listLots.length > 0"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        outlined
                        label="Nº"
                        v-model="lotEditing.lot"
                        hide-details
                        v-if="listLots.length === 0 || freeForm"
                      />
                      <v-autocomplete
                        v-else
                        :search-input.sync="lotEditingField"
                        v-model="lotEditing.lot"
                        :items="listLots.map((a) => a.lot)"
                        @input="changeLot"
                        outlined
                        label="Nº"
                        placeholder="Nº"
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        outlined
                        label="Título"
                        v-model="lotEditing.label"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        outlined
                        label="Pagamento"
                        v-model="lotEditing.payment"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        outlined
                        label="Localidade"
                        v-model="lotEditing.details.location"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        label="Idade"
                        v-model="lotEditing.details.age"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        label="Peso"
                        v-model="lotEditing.details.weight"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        label="Valor atual"
                        v-model="currentEditing"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex">
                      <v-btn class="ml-2" color="success" @click="clear">
                        <v-icon>mdi-cash-multiple</v-icon>
                        <span>Vendido</span>
                      </v-btn>
                      <v-btn
                        style="flex: 1"
                        class="ml-2"
                        color="primary"
                        type="submit"
                      >
                        <v-icon>mdi-send</v-icon>
                        <span>Enviar</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <lance-excel v-if="listLots.length === 0" />
                      <v-btn block color="error" v-else @click="clearListLots">
                        Esvaziar planilha
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" md="5" align="top">
                <v-row class="mx-2" no-gutters justify="center">
                  <v-col
                    cols="4"
                    v-for="n in [150, 100, 50, 30, 20, 10]"
                    :key="n"
                    class="pa-1"
                  >
                    <v-btn block color="info" @click="() => send(n)" x-large>
                      <span>{{ n | brl }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <online :socket="socket" />
      </v-col>
      <v-col sm="12" md="4">
        <v-mix :lot="lot" :current="current" />
      </v-col>
      <v-col sm="12" md="8">
        <stream :lot="lot" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import socketio from "socket.io-client";
import { isEqual } from "lodash";
import api from "@/api";
import Online from "../components/PainelLance/Online.vue";
import LanceExcel from "../components/PainelLance/LanceExcel.vue";
import VMix from "../components/PainelLance/ThirdParty/VMix.vue";
import Stream from "../components/PainelLance/Stream.vue";

export default {
  components: { Online, LanceExcel, VMix, Stream },
  name: "PainelStream",
  data: () => ({
    lotEditingField: "",
    listLots: [],
    socket: socketio(
      (api.defaults.baseURL.endsWith("/")
        ? api.defaults.baseURL
        : api.defaults.baseURL + "/") + "main",
      {
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionAttempts: 1000,
      }
    ),
    current: 1740,
    lot: {
      lot: "",
      label: "",
      payment: "",
      details: {
        age: "",
        weight: "",
        location: "",
      },
    },
    lotEditing: {
      lot: "",
      label: "",
      payment: "",
      details: {
        age: "",
        weight: "",
        location: "",
      },
    },
    currentEditing: 100,
    freeForm: false,
  }),
  filters: {
    brl(val) {
      if (!val && val !== 0) return null;
      return "R$" + Math.round(val - 0); // .replace('.', ',')
    },
  },
  methods: {
    submitForm() {
      this.lot = this.lotEditing;
      this.current = this.currentEditing * 1;
      this.$swal({
        showConfirmButton: false,
        timer: 3000,
        toast: true,
        position: "bottom-left",
        icon: "success",
        title: "Salvo com sucesso",
        background: "#",
      });
      this.socket.emit("refresh", {
        lot: this.lot,
        current: this.current,
      });
    },
    clear() {
      this.$root.$emit("clearPending");
      this.currentEditing = 0;
      this.lotEditing = "";
      this.lotEditing = {
        lot: "",
        label: "",
        payment: "",
        details: {
          age: "",
          weight: "",
          location: "",
        },
      };
      this.submitForm();
      this.freeForm = false;
    },
    send(bid) {
      this.current += bid;
      this.currentEditing = this.current;

      this.socket.emit(
        "bid",
        JSON.stringify({
          bid: bid,
          total: this.current,
          client_id: "admin",
        })
      );
    },
    imAdmin() {
      this.socket.emit("data");
      this.socket.emit("imadmin");
    },
    changeLot(v) {
      if (v && this.listLots.find((a) => a.lot == v)) {
        const lot = this.listLots.find((a) => a.lot == v);
        this.lotEditing = JSON.parse(JSON.stringify(lot));
      }
    },
    clearListLots() {
      api.post("/auction/list_lot", []);
    },
  },
  created() {
    window.socket = this.socket;
    this.socket.on("connect", this.imAdmin);
    this.socket.on("reconnect", this.imAdmin);
    this.socket.on("refresh", (data) => {
      data = JSON.parse(data);
      if (isEqual(this.lot, this.lotEditing))
        this.lotEditing = JSON.parse(JSON.stringify(data.lot));
      this.lot = data.lot;
      this.current = data.current;
      this.currentEditing = 1 - 1 + data.current;
    });
    this.socket.on("listLots", (v) => (this.listLots = v));
  },
  destroyed() {
    this.socket.close();
  },
};
</script>

<style></style>
