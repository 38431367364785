<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>Retransmissões</span>
      <v-btn text @click="add">
        <v-icon>mdi-plus</v-icon>
        <span>INSERIR</span>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item v-for="(item, i) in repushes" :key="i">
          <v-switch v-model="item.active" @change="() => toggleRepush(item)" />
          <v-list-item-content>
            <v-list-item-title> {{ item.name }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.rtmp.server }} <br />
              {{ item.rtmp.key }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex flex-row">
            <v-btn icon @click="() => edit(i)">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="() => deleteRow(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-form ref="form" @submit.prevent="submit">
      <v-dialog v-model="editModal" max-width="500px">
        <v-card v-if="editing">
          <v-card-title>
            {{ (editModal ? "Editar" : "criar") + " retransmissão" }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Identificador"
              v-model="editing.name"
              outlined
              :rules="[rules.required]"
            />
            <v-text-field
              label="Servidor - RTMP"
              v-model="editing.rtmp_server"
              outlined
              prefix="rtmp://"
              :rules="[rules.required]"
            />
            <v-text-field
              label="Chave - RTMP"
              v-model="editing.rtmp_key"
              outlined
              :rules="[rules.required]"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="closeModal">Cancelar</v-btn>
            <v-btn color="primary" type="submit" @click="submit">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Repushes",
  props: ["repushes"],
  data: () => ({
    editing: null,
    editModal: false,
    editIndex: null,

    rules: {
      required: (v) => !!v || "Campo obrigatório",
    },
  }),
  methods: {
    edit(i) {
      this.editing = this.repushes[i];
      this.editing = {
        name: this.editing.name,
        rtmp_key: this.editing.rtmp.key,
        rtmp_server: this.editing.rtmp.server,
      };
      this.editModal = true;
      this.editIndex = i;
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return alert("Informe todos os campos");
      }
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        if (this.editIndex === null)
          await api.post("/stream/repush", this.editing);
        else
          await api.put(
            `/stream/repush/${this.repushes[this.editIndex]._id}`,
            this.editing
          );
        console.log("oiasdasdd");
        this.$emit("refresh");
        this.closeModal();
      } catch (err) {
        console.log(err.response);
      }

      loader.hide();
    },
    closeModal() {
      this.editModal = false;
      this.editIndex = null;
      this.editing = {
        name: "",
        rtmp_server: "",
        rtmp_key: "",
      };
    },
    add() {
      this.editing = {
        name: "",
        rtmp_server: "",
        rtmp_key: "",
      };
      this.editModal = true;
      this.editIndex = null;
    },
    async toggleRepush(row) {
      try {
        await api.post(
          "/stream/repush/" +
            row._id +
            "/status/" +
            (row.active ? "true" : "false")
        );
      } catch (err) {
        if (err?.response?.data?.message)
          this.$swal(
            "Ops...",
            err?.response?.data?.message || "Ocorreu um erro",
            "error"
          );
        row.active = false;
      }
    },
    async deleteRow(i) {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        await api.delete("/stream/repush/" + this.repushes[i]._id);
        this.$emit("refresh");
      } catch (err) {
        console.error(err);
      }
      loader.hide();
    },
  },
};
</script>

<style></style>
