<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h3 class="text-h3">Painel de lances</h3>
      <v-btn color="primary" rounded to="/placar">
        <v-icon>mdi-view-dashboard</v-icon>
        <span>Abrir placar</span>
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12" md="7">
        <v-card class="mt-4">
          <v-card-text class="text-center mt-12">
            <chat :socket="socket" />
          </v-card-text>
        </v-card>
        <online :socket="socket" />
      </v-col>
      <v-col cols="12" md="5">
        <pending-card :socket="socket" />
        <history-card :socket="socket" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import socketio from "socket.io-client";
import { isEqual } from "lodash";
import api from "@/api";
import PendingCard from "../components/PainelLance/PendingCard.vue";
import HistoryCard from "../components/PainelLance/HistoryCard.vue";
import Online from "../components/PainelLance/Online.vue";
import Chat from "../components/PainelLance/Chat.vue";

export default {
  components: { PendingCard, HistoryCard, Online, Chat },
  name: "PainelLance",
  data: () => ({
    socket: socketio(
      (api.defaults.baseURL.endsWith("/")
        ? api.defaults.baseURL
        : api.defaults.baseURL + "/") + "main",
      {
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionAttempts: 1000,
      }
    ),
    current: 1740,
    lot: {
      lot: "",
      label: "",
      payment: "",
      details: {
        age: "",
        weight: "",
        location: "",
      },
    },
  }),
  filters: {
    brl(val) {
      if (!val && val !== 0) return null;
      return "R$" + Math.round(val - 0); // .replace('.', ',')
    },
  },
  methods: {
    imAdmin() {
      this.socket.emit("data");
      this.socket.emit("imadmin");
    },
  },
  created() {
    window.socket = this.socket;
    this.socket.on("connect", this.imAdmin);
    this.socket.on("reconnect", this.imAdmin);
    this.socket.on("refresh", (data) => {
      data = JSON.parse(data);
      this.lot = data.lot;
      this.current = data.current;
    });
    this.socket.on("listLots", (v) => (this.listLots = v));
  },
  destroyed() {
    this.socket.close();
  },
};
</script>

<style></style>
