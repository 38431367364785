<template>
  <v-card class="mb-8">
    <v-card-title>Visitantes</v-card-title>
    <v-card-text class="d-flex align-center flex-column flex-md-row">
      <div class="d-flex flex-column align-center primary--text">
        <span class="text-h1">{{ app }}</span>
        <span>APLICATIVO</span>
      </div>
      <div style="flex: 1; height: 10px; width: 90%" class="mx-4">
        <v-progress-linear
          height="10"
          class="rounded-pill"
          background-color="success"
          color="primary"
          :value="(app / (app + site)) * 100"
        ></v-progress-linear>
      </div>
      <div class="d-flex flex-column align-center success--text">
        <span class="text-h1">{{ site }}</span>
        <span>SITE</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";

export default {
  name: "Views",
  data: () => ({
    site: 0,
    app: 0,
  }),
  created() {
    this.get();
    window.timmerView = setInterval(this.get, 30000);
  },
  methods: {
    get() {
      api
        .get("/visit/?context=app")
        .then(({ data }) => (this.app = data.count));
      api
        .get("/visit/?context=site")
        .then(({ data }) => (this.site = data.count));
    },
  },
  destroyed() {
    if (window.timmerView) clearTimeout(window.timmerView);
  },
};
</script>

<style></style>
