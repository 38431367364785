import Vue from "vue";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 30000,
  headers: { "X-Custom-Header": "foobar" },
});

api.interceptors.request.use(function (config) {
  config.headers["Content-Type"] = "application/json";
  const token = localStorage.getItem("tk");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (!config.params) config.params = {};
  config.params.project = Vue.prototype.$p.name;
  return config;
});

export default api;
