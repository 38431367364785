<template>
  <div>
    <h3 class="text-h3">Página inicial</h3>
    <v-card class="mt-4" v-if="$p.name === 'sindpatos'">
      <v-card-text>
        <div class="d-flex justify-space-between mb-4">
          <h3 class="text-h4">Benefícios</h3>
          <v-btn text color="primary" @click="add">
            <v-icon>mdi-plus</v-icon>
            <span>Novo</span>
          </v-btn>
        </div>
        <v-data-table :items="beneficios" :headers="beneficiosHeader">
          <template v-slot:[`item.icon`]="{ item }">
            <v-responsive
              :aspect-ratio="1"
              style="max-width: 60px"
              class="primary"
            >
              <v-img :src="item.thumb" />
            </v-responsive>
          </template>
          <template v-slot:[`item.actions`]="{ index }">
            <v-btn
              fab
              color="primary"
              x-small
              class="mr-4"
              @click="() => edit(index)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn fab color="error" x-small>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-text>
        <div class="d-flex justify-space-between mb-4">
          <h3 class="text-h4">Banner</h3>
        </div>
        <div class="d-flex flex-column justify-center" style="max-width: 500px">
          <img :src="bannerHomePreview" style="width: 100%" />
          <v-file-input
            accept="image/png, image/jpeg"
            v-model="bannerHome"
            class="mt-4"
            outlined
            dense
            hide-details
          />
        </div>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-end mt-4">
      <v-btn color="primary" @click="submit">
        <v-icon>mdi-content-save-outline</v-icon>
        <span>Salvar</span>
      </v-btn>
    </div>
    <form-benefits
      ref="formEdit"
      v-if="editing || editing === 0"
      v-model="beneficios[editing]"
    />
  </div>
</template>

<script>
import FormBenefits from "@/components/FormBenefits.vue";
import api from "@/api";
import sendImage from "@/upload";

export default {
  name: "ContentHome",
  components: { FormBenefits },
  data: () => ({
    editing: null,
    beneficios: [],
    beneficiosHeader: [
      {
        text: "Icone",
        value: "icon",
        width: "120px",
      },
      { text: "Nome", value: "title" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions", width: "120px" },
    ],

    bannerHome: "",
  }),
  created() {
    this.get();
  },
  methods: {
    edit(index) {
      this.editing = index;
      if (this.$refs["formEdit"]) {
        this.$refs.formEdit.open = true;
      }
    },
    add() {
      const index = this.beneficios.push({
        title: "",
        description: "",
        icon: "",
      });
      this.edit(index - 1);
    },
    async submit() {
      api
        .patch("/config/set", {
          benefits: this.beneficios,
          bannerHome:
            this.bannerHome && typeof this.bannerHome === "object"
              ? await sendImage(this.bannerHome, {
                  width: 1161,
                  height: 545,
                  thumb: ".1",
                })
              : this.bannerHome || "",
        })
        .then(() => alert("Dados salvos com sucesso!"))
        .catch((e) => console.error(e));
    },
    async get() {
      const { data } = await api.get("/config/get", {
        params: {
          keys: "benefits,bannerHome",
        },
      });
      this.beneficios = data.benefits || [];
      this.bannerHome = data.bannerHome ? data.bannerHome.image : "";
    },
  },
  computed: {
    bannerHomePreview() {
      if (!this.bannerHome) return;
      if (typeof this.bannerHome === "string") return this.bannerHome;
      return URL.createObjectURL(this.bannerHome);
    },
  },
};
</script>

<style></style>
