import Vue from "vue";
import project from "../projects";

Vue.use(project);

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.config.productionTip = false;

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/main.scss";

Vue.use(VueSweetalert2);

Vue.use(VueLoading);

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: "mdi",
});

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBE2Jue91YZwSGmBDA9UMeqc5sa_57HTSI",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import Vueditor from "vueditor";
import "vueditor/dist/style/vueditor.min.css";

// your config here
const config = {
  toolbar: [
    "removeFormat",
    "undo",
    "|",
    "elements",
    "fontName",
    "fontSize",
    "foreColor",
    "backColor",
  ],
  fontName: [
    { val: "Montserrat" },
    { val: "times new roman" },
    { val: "Courier New" },
  ],
  fontSize: [
    "12px",
    "14px",
    "16px",
    "18px",
    "0.8rem",
    "1.0rem",
    "1.2rem",
    "1.5rem",
    "2.0rem",
  ],
  uploadUrl: "",
};

Vue.use(Vueditor, config);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
