import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Users from "../views/Users.vue";
import UsersForm from "../views/UsersForm.vue";
import Layout from "../views/Layout.vue";
import Login from "../views/Login.vue";

import Carousels from "../views/Carousels/Carousels.vue";
import CarouselsForm from "../views/Carousels/CarouselsForm.vue";

import Events from "../views/Events/Events.vue";
import EventForm from "../views/Events/EventForm.vue";

import Partners from "../views/Partners/Partners.vue";
import PartnerForm from "../views/Partners/PartnerForm.vue";

import Clients from "../views/Clients.vue";

import Contacts from "../views/Contacts.vue";

import Config from "../views/Config.vue";

import ContentHome from "../views/ContentHome.vue";

import ContentSobre from "../views/ContentSobre.vue";

import ContentLeilao from "../views/ContentLeilao.vue";

import ContentCadastro from "../views/ContentCadastro.vue";

import PainelLance from "../views/PainelLance.vue";

import PainelStream from "../views/PainelStream.vue";

import ScoreBoard from "../views/ScoreBoard.vue";

import store from "../store";

Vue.use(VueRouter);

const guard = (_to, _from, next) => {
  if (localStorage.tk) return next();
  next("/logout");
  return false;
};

const routes: Array<RouteConfig> = [
  {
    path: "/placar",
    name: "ScoreBoard",
    beforeEnter: guard,
    component: ScoreBoard,
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    beforeEnter: guard,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/usuarios",
        name: "Usuarios",
        component: Users,
      },
      {
        path: "/criar-usuario",
        name: "UsuarioNovo",
        component: UsersForm,
      },
      {
        path: "/editar-usuario/:id",
        name: "UsuariosEditar",
        component: UsersForm,
      },

      {
        path: "/carousels",
        name: "Carousels",
        component: Carousels,
      },
      {
        path: "/criar-carousel",
        name: "CarouselNovo",
        component: CarouselsForm,
      },
      {
        path: "/editar-carousel/:id",
        name: "CarouselEditar",
        component: CarouselsForm,
      },

      {
        path: "/eventos",
        name: "Events",
        component: Events,
      },
      {
        path: "/criar-evento",
        name: "EventoNovo",
        component: EventForm,
      },
      {
        path: "/editar-evento/:id",
        name: "EventoEditar",
        component: EventForm,
      },

      {
        path: "/parceiros",
        name: "Partners",
        component: Partners,
      },
      {
        path: "/criar-parceiro",
        name: "PartnerNovo",
        component: PartnerForm,
      },
      {
        path: "/editar-parceiro/:id",
        name: "PartnerEditar",
        component: PartnerForm,
      },

      {
        path: "/cadastros",
        name: "Clients",
        component: Clients,
      },

      {
        path: "/contatos",
        name: "Contacts",
        component: Contacts,
      },

      {
        path: "/configuracoes",
        name: "Config",
        component: Config,
      },

      {
        path: "/conteudo-home",
        name: "ContentHome",
        component: ContentHome,
      },

      {
        path: "/conteudo-sobre",
        name: "ContentSobre",
        component: ContentSobre,
      },

      {
        path: "/conteudo-leilao",
        name: "ContentLeilao",
        component: ContentLeilao,
      },

      {
        path: "/conteudo-cadastro",
        name: "ContentCadastro",
        component: ContentCadastro,
      },

      {
        path: "/lance",
        name: "PainelLance",
        component: PainelLance,
      },

      { path: "/stream", name: "PainelStream", component: PainelStream },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      store.commit("SET_USER", false);
      localStorage.removeItem("tk");
      next("/login");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
