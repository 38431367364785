j
<template>
  <div>
    <h3 class="text-h3">Evento</h3>
    <v-card class="mt-4">
      <v-card-text>
        <form @submit.prevent="submit">
          <v-text-field label="Nome" outlined v-model="name" />
          <v-text-field
            label="Localização"
            outlined
            v-model="location"
            append-icon="mdi-map"
          />
          <GmapMap
            ref="mapRef"
            :center="mapLocation || { lat: -18.9742714, lng: -48.2294025 }"
            :zoom="mapLocation ? 10 : 5"
            style="height: 300px"
          >
            <GmapMarker v-if="mapLocation" :position="mapLocation" />
          </GmapMap>
          <v-switch
            v-if="$p.name === 'rubinho'"
            label="Transmissão ao vivo"
            v-model="live"
          />
          <v-text-field
            outlined
            label="ID Playlist - Youtube"
            v-if="$p.project === 'rubinho'"
            v-model="playlistId"
          />
          <v-file-input
            accept="image/png, image/jpeg, application/pdf"
            placeholder="Escolha a imagem"
            prepend-icon="mdi-file"
            label="Catalogo de lotes"
            outlined
            v-model="pdf"
            class="mt-8"
            hide-details
          ></v-file-input>
          <span v-if="pdf && typeof pdf === 'string'">
            Selecionado: <a :href="pdf" target="_blank">{{ pdf }}</a>
          </span>
          <v-row class="my-0 mt-8">
            <v-col cols="12" md="2" class="py-0">
              <v-switch label="Repetição" v-model="enableScheduling" />
            </v-col>
            <v-col cols="12" md="5" class="py-4 py-md-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    hide-details
                    v-model="date"
                    outlined
                    label="Data do evento"
                    append-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :disabled="enableScheduling"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable />
              </v-menu>
            </v-col>
            <v-col cols="12" md="5" class="py-4 py-md-0">
              <v-menu
                offset-y
                :close-on-content-click="false"
                v-model="openHourMenu"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex align-center">
                    <v-text-field
                      hide-details
                      v-model="time"
                      outlined
                      label="Hora do evento"
                      append-icon="mdi-clock"
                    ></v-text-field>
                    <v-btn icon v-on="on">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-time-picker v-model="time" format="24hr" />
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" v-if="enableScheduling">
              <v-select
                outlined
                :disabled="!schedulingTypeOptions"
                v-model="schedulingType"
                :items="schedulingTypeOptions || []"
                label="Periodo de repetição"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="enableScheduling"
              class="d-flex align-center"
            >
              <v-tooltip
                v-if="schedulingType === 'biweekly'"
                top
                :open-on-click="$vuetify.breakpoint.mobile"
                :open-on-hover="!$vuetify.breakpoint.mobile"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on"
                    ><v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                Semana atual é
                <b>{{ currentWeek % 2 === 0 ? "par" : "impar" }}</b>
              </v-tooltip>
              <v-select
                outlined
                v-model="schedulingDay"
                :items="schedulingOptions || []"
                label="Dia da repetição"
                hide-details
              />
            </v-col>
          </v-row>
          <h3 class="text-caption">Descrição</h3>
          <div
            style="
              border: solid 1px rgba(0, 0, 0, 0.38);
              border-radius: 4px;
              padding: 4px;
            "
          >
            <tiptap-vuetify
              v-model="description"
              :extensions="extensions"
              icon="mdi"
              :cardProps="{ elevation: 0 }"
            />
          </div>
          <div class="d-flex pt-10">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Banner"
              outlined
              v-model="picture"
            ></v-file-input>
            <img
              :src="urlImage"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <lot-edit
            @save="saveLot"
            :lot="editingLot"
            ref="modalLot"
            v-if="editingLot"
          />
          <v-row class="my-10">
            <v-col cols="12" class="d-flex justify-space-between">
              <h2>Lotes</h2>
              <v-btn @click="addLot">
                <v-icon>mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </v-col>
            <v-col cols="6" md="3" v-for="(lot, i) in lots" :key="i">
              <v-img
                :aspect-ratio="16 / 9"
                class="rounded-lg align-end"
                gradient="transparent, black"
                :src="
                  lot.media[0]
                    ? lot.media[0].startsWith('http')
                      ? lot.media[0]
                      : `https://img.youtube.com/vi/${lot.media[0]}/0.jpg`
                    : ''
                "
                dark
              >
                <div class="pa-5 d-flex justify-space-between">
                  <h3>Lote {{ lot.lot }}</h3>
                  <v-btn icon @click="() => editLot(i)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </v-col>
          </v-row>
          <v-btn @click="submit" color="primary">Salvar</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import api from "@/api";
import LotEdit from "@/components/LotEdit.vue";
import Vue from "vue";

export default {
  name: "EventForm",
  components: { TiptapVuetify, LotEdit },
  data: () => ({
    name: "",
    date: "",
    time: "",
    picture: "",
    description: "",
    location: "",
    live: false,

    pdf: "",
    playlistId: "",

    mapLocation: null,

    lots: [],

    openHourMenu: false,
    enableScheduling: false,
    schedulingType: "weekly",
    schedulingDay: null,

    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    schedulingTypeOptions: [
      { text: "Semanal", value: "weekly" },
      { text: "Bissemanal", value: "biweekly" },
      { text: "Mensal", value: "monthly" },
    ],
    currentWeek: moment().week(),
    daysOfWeek: [
      "Domingo",
      "Segunda",
      "Terca",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sabádo",
    ],
    editingLot: null,
  }),
  methods: {
    sendImage(file, format, size) {
      return new Promise((result) => {
        var formData = new FormData();
        if (size) formData.append("width", "500");
        if (size) formData.append("height", "500");
        formData.append("format", format || "png");
        if (!format) formData.append("thumb", ".2");
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    validate() {
      if (!this.name || !this.picture || !this.time) {
        this.$swal("Ops..", "Informe todos os dados.", "error");
      } else return true;
    },
    async submit() {
      if (!this.validate()) return;
      const payload = {
        name: this.name,
        location: this.location,
        pdf: this.pdf,
        playlistId: this.playlistId,
        map_location: this.mapLocation,
        date: this.date || null,
        time: this.time,
        description: this.description,
        lots: this.lots || [],
        live: this.live,
        scheduling: this.enableScheduling
          ? { schedulingType: this.schedulingType, day: this.schedulingDay }
          : { schedulingType: null, day: null },
      };

      const loader = this.$loading.show({
        // Optional parameters
        color: this.$vuetify.theme.themes.light.primary,
      });
      if (this.pdf && typeof this.pdf === "object") {
        const upload = await this.sendImage(
          this.pdf,
          this.pdf && this.pdf.name.endsWith("pdf") ? "pdf" : "png",
          false
        );
        payload.pdf = upload.image;
      }
      if (typeof this.picture === "object") {
        const upload = await this.sendImage(this.picture);
        payload.image = upload.image;
        payload.thumb = upload.thumb;
      }

      try {
        const {
          data: { _id },
        } = await api[this.isEdit ? "patch" : "post"](
          "/auction/" + (this.$route.params.id || ""),
          payload
        );
        if (!this.isEdit) this.$router.push("/editar-evento/" + _id);
        this.$swal("Ótimo!", "Alterações salvas com sucesso!", "success");
        loader.hide();
      } catch (err) {
        console.error(err);
        this.$swal("Ops!", "Ocorreu um problema!", "error");
        loader.hide();
      }
    },

    async get(id) {
      const { data } = await api.get("/auction/" + id);
      this.name = data.name;
      this.location = data.location;
      this.mapLocation = data.mapLocation;
      this.date = (
        data.date ? moment(data.date).utcOffset(data.date) : moment()
      ).format("YYYY-MM-DD");
      this.time = data.time;
      this.description = data.description;
      this.picture = data.image;
      this.lots = data.lots;
      this.pdf = data.pdf;
      this.playlistId = data.playlistId;
      this.live = data.live;
      this.schedulingType = data.scheduling.schedulingType;
      this.schedulingDay = data.scheduling.day;

      if (data.scheduling.schedulingType) this.enableScheduling = true;
    },
    async init() {
      if (this.isEdit) this.get(this.$route.params.id);
      else {
        this.name = "";
        this.url = "";
        this.picture = "";
      }
    },
    getLocation: debounce(function () {
      if (!window.autocomplete_services)
        window.autocomplete_services =
          new window.google.maps.places.AutocompleteService();
      if (!window.geocoder) window.geocoder = new window.google.maps.Geocoder();

      window.autocomplete_services.getPlacePredictions(
        {
          input: this.location,
          componentRestrictions: { country: "BR" },
        },
        (results) => {
          if (!results[0]) return (this.mapLocation = null);
          window.geocoder.geocode(
            {
              placeId: results[0].place_id,
            },
            (results) => {
              if (!results[0]) return (this.mapLocation = null);
              this.mapLocation = results[0].geometry.location;
            }
          );
        }
      );
    }, 500),

    addLot() {
      this.editingLot = {
        lot: "",
        description: "",
        location: "",
        media: [],
      };
      setTimeout(() => (this.$refs.modalLot.open = true), 300);
    },

    saveLot(payload) {
      const obj = {
        lot: payload.lot,
        description: payload.description,
        location: payload.location,
        media: payload.media,
      };

      if (payload.index || payload.index === 0) {
        Vue.set(this.lots, payload.index, obj);
      } else {
        this.lots.push(obj);
      }
    },

    editLot(index) {
      this.editingLot = {
        ...this.lots[index],
        index,
      };
      setTimeout(() => (this.$refs.modalLot.open = true), 300);
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
    time(v) {
      this.openHourMenu = false;
    },
    location() {
      this.getLocation();
    },
    playlistId(v) {
      if (v.startsWith("http"))
        this.playlistId = this.playlistId
          .match(/((list=)?)([a-zA-Z0-9_-]{12,1000})/)
          .find((a) => a.indexOf("=") === -1);
    },
  },
  computed: {
    urlImage() {
      if (!this.picture) return;
      if (typeof this.picture === "string") return this.picture;
      return URL.createObjectURL(this.picture);
    },
    isEdit() {
      return this.$route.name.indexOf("Editar") > -1;
    },
    schedulingOptions() {
      if (!this.schedulingType) return null;
      else if (this.schedulingType === "weekly")
        return this.daysOfWeek.map((a, i) => ({ text: a, value: i }));
      else if (this.schedulingType === "biweekly")
        return this.daysOfWeek.reduce(
          (r, a, i) => [
            ...r,
            { text: a + " - Semanas pares", value: i },
            { text: a + " - Semanas impares", value: i + 10 },
          ],
          []
        );
      else if (this.schedulingType === "monthly")
        return new Array(31).fill().map((_a, i) => i + 1);
      return null;
    },
  },
};
</script>

<style>
.tiptap-vuetify-editor__content > div {
  min-height: 300px;
}
</style>
