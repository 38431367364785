<template>
  <v-app style="background: #fcfcfc">
    <v-app-bar
      app
      color="white"
      elevation="0"
      style="
        border-bottom: 1px solid #f1f1f1;
        box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 3%) !important;
      "
    >
      <v-app-bar-nav-icon
        @click="menu = !menu"
        v-if="$vuetify.breakpoint.mobile"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="user">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <span class="font-weight-bold">{{ user.name }}</span>
            <v-avatar color="primary" size="40" class="ml-4">
              <v-img :src="user.picture" v-if="user.picture" />
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
          </div>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item :to="`/editar-usuario/${$store.state.user._id}`">
              <v-list-item-title>Meus dados</v-list-item-title>
            </v-list-item>
            <v-list-item to="/configuracoes">
              <v-list-item-title>Configuracoes</v-list-item-title>
            </v-list-item>
            <v-list-item to="/logout">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      :temporary="$vuetify.breakpoint.mobile"
      :permanent="!$vuetify.breakpoint.mobile"
      app
      color="primary"
      v-model="menu"
      dark
    >
      <v-img class="ma-6 mt-6" max-height="100px" contain :src="$p.whiteLogo" />
      <v-list>
        <div
          v-for="item in items.filter(
            (a) =>
              !$store.state.user ||
              !$store.state.user.abilitys ||
              (!a.module &&
                $store.state.user.abilitys.filter((a) =>
                  this.$p.modules.includes(a)
                ).length > 3) ||
              $store.state.user.abilitys.includes(a.module)
          )"
          :key="item.title"
        >
          <v-list-group
            v-if="item.submenu"
            :prepend-icon="item.icon"
            color="white"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </template>

            <v-list-item
              link
              v-for="(subitem, ii) in item.submenu"
              :key="ii"
              :to="subitem.route"
            >
              <v-list-item-content class="pl-5">
                <v-list-item-title v-text="subitem.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link v-else :to="item.route">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main class="ma-5 mb-0">
      <v-container style="max-width: 1300px">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data() {
    return {
      menu: false,
      items: [
        {
          title: "Visão Geral",
          icon: "mdi-view-dashboard",
          route: "/",
        },
        {
          title: "Conteúdo",
          icon: "mdi-book-open-page-variant-outline",

          submenu: [
            { title: "Página inicial", route: "/conteudo-home" },
            { title: "Sobre", route: "/conteudo-sobre" },
            { title: "Leilão", route: "/conteudo-leilao" },
            { title: "Cadastro", route: "/conteudo-cadastro" },
          ],
          module: "content-home",
        },
        {
          title: "Eventos",
          icon: "mdi-calendar",
          submenu: [
            { title: "Ver eventos", route: "/eventos" },
            { title: "Adicionar", route: "/criar-evento" },
          ],
          module: "events",
        },
        {
          title: "Usuários",
          icon: "mdi-account",
          submenu: [
            { title: "Ver usuários", route: "/usuarios" },
            { title: "Adicionar", route: "/criar-usuario" },
          ],
          module: "users",
        },
        {
          title: "Carousel",
          icon: "mdi-view-carousel",
          submenu: [
            { title: "Ver", route: "/carousels" },
            { title: "Adicionar", route: "/criar-carousel" },
          ],
          module: "carousel",
        },
        {
          title: "Parceiros",
          icon: "mdi-handshake",
          submenu: [
            { title: "Ver", route: "/parceiros" },
            { title: "Adicionar", route: "/criar-parceiro" },
          ],
          module: "partners",
        },
        {
          title: "Cadastros",
          icon: "mdi-account-group",
          route: "/cadastros",
          module: "clients",
        },
        {
          title: "Contato",
          icon: "mdi-at",
          route: "/contatos",
          module: "contact",
        },
        {
          title: "Painel de lances",
          icon: "mdi-monitor-eye",
          route: "/lance",
          module: "painel-lance",
        },
        {
          title: "Transmissão",
          icon: "mdi-broadcast",
          route: "/stream",
          module: "painel-stream",
        },
      ].filter((a) => this.$p.modules.includes(a.module) || !a.module),
    };
  },
  async created() {
    if (!(await this.$store.dispatch("fetchUser"))) {
      this.$router.push("/logout");
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
});
</script>
