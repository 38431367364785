<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Benefício</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field label="Título" outlined v-model="title" />

          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a foto de perfil"
              prepend-icon="mdi-camera"
              label="Avatar"
              outlined
              v-model="image"
            ></v-file-input>
            <img
              :src="url"
              width="55px"
              height="55px"
              class="ml-5 green"
              style="object-fit: contain"
            />
          </div>
          <v-textarea label="Título" outlined v-model="description" />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="open = false"> Fechar </v-btn>
        <v-btn color="blue darken-1" text @click="submit"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "FormBenefits",
  data: () => ({
    title: "",
    image: "",
    description: "",
    open: true,
  }),
  props: ["value"],
  created() {
    this.setNewData();
  },
  methods: {
    sendImage(file) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("width", "300");
        formData.append("height", "300");
        formData.append("format", "png");
        formData.append("thumb", ".2");
        formData.append("file", file);
        formData.append("contain", "true");
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    async submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        const payload = {
          ...this.value,
          title: this.title,
          icon: this.image,
          description: this.description,
        };

        if (typeof this.image === "object") {
          const upload = await this.sendImage(this.image);

          payload.icon = upload.image;
          payload.thumb = upload.thumb;
        }
        this.$emit("input", payload);
        this.open = false;

        loader.hide();
      } catch (err) {
        console.error(err);
        loader.hide();
      }
    },
    setNewData() {
      const v = this.value;
      if (!v) return;
      this.title = v.title;
      this.image = v.icon;
      this.description = v.description;
    },
  },
  computed: {
    url() {
      if (!this.image) return;
      if (typeof this.image === "string") return this.image;
      return URL.createObjectURL(this.image);
    },
  },
  watch: {
    value() {
      this.setNewData();
    },
  },
};
</script>
