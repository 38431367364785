/* eslint-disable */
import api from "@/api";

const sendImage = (file, { width, height, thumb, contain }) => {
  return new Promise((result) => {
    var formData = new FormData();
    if (width) formData.append("width", width);
    if (height) formData.append("height", height);
    if (thumb) formData.append("thumb", thumb);
    if (contain) formData.append("contain", "true");
    formData.append("file", file);
    api
      .post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => result(data));
  });
};

export default sendImage;
