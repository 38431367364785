<template>
  <div>
    <h3 class="text-h3">Usuário</h3>
    <v-card class="mt-4">
      <v-card-text>
        <form @submit.prevent="submit">
          <v-text-field label="Nome" outlined v-model="name" />
          <v-text-field label="E-mail" type="email" outlined v-model="email" />
          <v-row v-if="!isEdit">
            <v-col cols="6">
              <v-text-field label="Senha" outlined v-model="password" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Confirmação de senha"
                outlined
                v-model="password_confirm"
              />
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a foto de perfil"
              prepend-icon="mdi-camera"
              label="Avatar"
              outlined
              v-model="picture"
            ></v-file-input>
            <img
              :src="url"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <v-row>
            <v-col
              v-for="(value, key) in abilityOptions"
              :key="key"
              cols="12"
              md="4"
            >
              <v-checkbox
                v-model="abilitys"
                :label="typeof value === 'string' ? value : value.name"
                hide-details
                :value="key"
              />
            </v-col>
          </v-row>
          <v-btn @click="submit" color="primary" class="mt-12">Salvar</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "UsersForm",
  data: () => ({
    name: "",
    email: "",
    password: "",
    password_confirm: "",
    picture: "",
    abilitys: [],
    originalAbilityOptions: {
      "painel-lance": {
        name: "Painel de lances",
        clients: ["rubinho"],
      },
      "painel-stream": {
        name: "Painel de transmissão",
        clients: ["rubinho"],
      },
      users: "Controle de usuários",
      carousel: "Controle de carousel",
      partners: "Controle de parceiros",
      clients: "Gerencamento de cadastros",
      contact: "Gerencamento de contatos",
      "content-home": "Conteúdo página inicial",
      events: "Controle de eventos",
    },
  }),
  methods: {
    sendImage(file) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("width", "100");
        formData.append("height", "100");
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    validate() {
      if (
        !this.name ||
        (!this.isEdit && (!this.password || !this.password_confirm)) ||
        !this.email
      ) {
        this.$swal("Ops..", "Informe todos os dados.", "error");
      } else if (this.password !== this.password_confirm) {
        this.$swal("Ops..", "Senha não são iguais", "error");
      } else return true;
    },
    async submit() {
      if (!this.validate()) return;

      const loader = this.$loading.show({
        // Optional parameters
        color: this.$vuetify.theme.themes.light.primary,
      });

      const payload = {
        name: this.name,
        email: this.email,
        password: this.password,
        abilitys: this.abilitys,
        picture:
          typeof this.picture === "object"
            ? (await this.sendImage(this.picture)).image
            : this.picture,
      };

      try {
        const { data } = await api[this.isEdit ? "patch" : "post"](
          "/user/" + (this.$route.params.id || ""),
          payload
        );
        if (!this.isEdit) this.$router.push("/editar-usuario/" + data._id);
        this.$swal("Ótimo!", "Alterações salvas com sucesso!", "success");
        loader.hide();
      } catch (err) {
        console.error(err);
        this.$swal("Ops!", "Ocorreu um problema!", "error");
        loader.hide();
      }
    },

    async getUser(id) {
      const { data } = await api.get("/user/" + id);
      this.name = data.name;
      this.email = data.email;
      this.picture = data.picture;
      this.abilitys = data.abilitys;
      return data;
    },
    async init() {
      if (this.isEdit) this.getUser(this.$route.params.id);
      else {
        this.name = "";
        this.email = "";
        this.password = "";
        this.password_confirm = "";
        this.picture = "";
      }
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    url() {
      if (!this.picture) return;
      if (typeof this.picture === "string") return this.picture;
      return URL.createObjectURL(this.picture);
    },
    isEdit() {
      return this.$route.name === "UsuariosEditar";
    },
    abilityOptions() {
      console.log(this.originalAbilityOptions);
      const data = Object.entries(this.originalAbilityOptions);
      for (let i = 0; i < data.length; i++) {
        const [key, value] = data[i];
        if (value && value.clients && !value.clients.includes(this.$p.name))
          delete this.originalAbilityOptions[key];
      }
      return this.originalAbilityOptions;
    },
  },
};
</script>

<style></style>
