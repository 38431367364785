var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('h3',{staticClass:"text-h3"},[_vm._v("Cadastros")]),_c('img',{staticStyle:{"display":"none"},attrs:{"src":_vm.$p.logo,"id":"logo"}}),_c('p',[_vm._v("Clientes que realizaram cadastro na plataformas")])]),_c('iframe',{staticStyle:{"display":"none"}}),_c('div',[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.downloadCsv}},[_c('v-icon',[_vm._v("mdi-microsoft-excel ")]),_c('span',[_vm._v("Baixar planilha")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clients,"items-per-page":5,"show-select":"","item-key":"_id","search":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-5"},[_c('v-img',{attrs:{"aspect-ratio":1,"src":item.image}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 'ACCEPTED' ? 'success' : 'warning'}},[_vm._v(_vm._s(item.status === "ACCEPTED" ? "APROVADO" : "AGUARDANDO"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":item.status === 'ACCEPTED' ? 'warning' : 'success'},on:{"click":function () { return _vm.setStatus(
              item._id,
              item.status === 'ACCEPTED' ? 'NOT ACCEPTED' : 'ACCEPTED'
            ); }}},[_c('v-icon',[_vm._v(_vm._s(item.status === "ACCEPTED" ? "mdi-account-cancel" : "mdi-account-check"))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.status === "ACCEPTED" ? "DESATIVAR" : "APROVAR"))])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function () { return _vm.deleteRow(item._id); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function () { return _vm.editRow(item._id); }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function () { return _vm.printRow(item._id); }}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }