var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background":"#fcfcfc"}},[_c('v-app-bar',{staticStyle:{"border-bottom":"1px solid #f1f1f1","box-shadow":"1px 2px 4px 0 rgb(0 0 0 / 3%) !important"},attrs:{"app":"","color":"white","elevation":"0"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.menu = !_vm.menu}}}):_vm._e(),_c('v-spacer'),(_vm.user)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.user.name))]),_c('v-avatar',{staticClass:"ml-4",attrs:{"color":"primary","size":"40"}},[(_vm.user.picture)?_c('v-img',{attrs:{"src":_vm.user.picture}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1)]}}],null,false,2848119316)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":("/editar-usuario/" + (_vm.$store.state.user._id))}},[_c('v-list-item-title',[_vm._v("Meus dados")])],1),_c('v-list-item',{attrs:{"to":"/configuracoes"}},[_c('v-list-item-title',[_vm._v("Configuracoes")])],1),_c('v-list-item',{attrs:{"to":"/logout"}},[_c('v-list-item-title',[_vm._v("Sair")])],1)],1)],1)],1):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"temporary":_vm.$vuetify.breakpoint.mobile,"permanent":!_vm.$vuetify.breakpoint.mobile,"app":"","color":"primary","dark":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-img',{staticClass:"ma-6 mt-6",attrs:{"max-height":"100px","contain":"","src":_vm.$p.whiteLogo}}),_c('v-list',_vm._l((_vm.items.filter(
          function (a) { return !_vm.$store.state.user ||
            !_vm.$store.state.user.abilitys ||
            (!a.module &&
              _vm.$store.state.user.abilitys.filter(function (a) { return this$1.$p.modules.includes(a); }
              ).length > 3) ||
            _vm.$store.state.user.abilitys.includes(a.module); }
        )),function(item){return _c('div',{key:item.title},[(item.submenu)?_c('v-list-group',{attrs:{"prepend-icon":item.icon,"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},_vm._l((item.submenu),function(subitem,ii){return _c('v-list-item',{key:ii,attrs:{"link":"","to":subitem.route}},[_c('v-list-item-content',{staticClass:"pl-5"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subitem.title)}})],1)],1)}),1):_c('v-list-item',{attrs:{"link":"","to":item.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)],1)}),0)],1),_c('v-main',{staticClass:"ma-5 mb-0"},[_c('v-container',{staticStyle:{"max-width":"1300px"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }