<template>
  <v-row v-if="stream">
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title>Transmissão</v-card-title>
        <v-card-text>
          <!-- <div class="d-flex justify-center">
            <v-switch v-model="live" label="Ao vivo" color="success" />
          </div> -->
          <div>
            <v-text-field
              :value="'rtmp://stream2.leilooa.com.br:1935/app'"
              outlined
              dense
              :style="{ fontSize: '14px' }"
              readonly
              label="RTMP - Servidor"
            />
            <v-text-field
              :value="$p.name"
              outlined
              dense
              :style="{ fontSize: '14px' }"
              readonly
              label="RTMP - Chave"
            />
          </div>
          <!-- <span class="d-table mx-auto" v-else-if="live && stream.active">
            Aguarde... Estamos configurando a transmissão!
          </span> -->
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <repushes :repushes="stream.repushes" @refresh="get" />
    </v-col>
    <v-dialog v-model="loading" persistent max-width="500px">
      <v-progress-linear
        color="light-blue"
        height="10"
        :value="loadingPercent"
        striped
      ></v-progress-linear>
    </v-dialog>
  </v-row>
</template>

<script>
import api from "@/api";
import Repushes from "./Repushes.vue";

export default {
  name: "Stream",
  components: { Repushes },
  data: () => ({
    stream: null,
    live: true,
    loading: false,
    loadingPercent: 5,

    tested: false,
  }),
  created() {
    this.get();
  },
  methods: {
    get() {
      api.get("/stream").then(({ data }) => {
        this.stream = data;
        this.live = this.stream.active;
        this.testPing();
      });
    },
    start() {
      this.loading = true;
      this.loadingPercent = 5;
      this.tested = false;
      let timmer;

      api.post("/stream/start").then(({ data }) => {
        setTimeout(() => {
          this.get();
          if (timmer) clearInterval(timmer);
          this.loadingPercent = 1000;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }, 15000);
      });

      timmer = setInterval(() => {
        this.loadingPercent += 5;
        if (this.loadingPercent >= 45) {
          clearInterval(timmer);
          timmer = setInterval(() => {
            this.loadingPercent += 7;
            if (this.loadingPercent >= 90) clearInterval(timmer);
          }, 8000);
        }
      }, 1000);
    },
    async stop() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      await api.post("/stream/stop");
      loader.hide();
      this.get();
      this.tested = false;
    },
    testPing() {
      console.log("oi");
      if (this.tested || !this.stream.ip || !this.stream.active) return;
      const socket = new WebSocket(
        `${location.protocol === "https:" ? "wss" : "ws"}://${
          this.stream.hostname
        }:${location.protocol === "https:" ? 3334 : 3333}/app/stream`
      );
      console.log(socket);
      socket.onerror = () => (this.tested = false);

      socket.onopen = () => {
        this.tested = true;
        setTimeout(() => socket.close(), 1000);
      };

      setTimeout(() => socket.close(), 3000);
    },
  },
  mounted() {
    console.log("oooo---");
    if (window.pingTimmerStream) clearInterval(window.pingTimmerStream);
    window.pingTimmerStream = setInterval(this.testPing, 7000);

    if (window.pingTimmerGetStream) clearInterval(window.pingTimmerGetStream);
    window.pingTimmerGetStream = setInterval(this.get, 13000);
  },
  watch: {
    live(v) {
      if (v) {
        this.start();
      } else this.stop();
    },
  },
};
</script>

<style></style>
