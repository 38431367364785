<template>
  <div>
    <h3 class="text-h3">Configurações</h3>
    <v-card class="mt-4">
      <v-card-text>
        <form @submit.prevent="submit">
          <v-text-field label="Título" outlined v-model="config.title" />
          <v-text-field
            label="Descrição"
            outlined
            v-model="config.description"
          />
          <v-text-field
            label="Youtube ID"
            outlined
            v-model="config.youtubeChannelId"
          />
          <v-text-field
            label="Link do youtube"
            outlined
            v-model="config.youtube_url"
          />
          <v-text-field
            label="Link do Instagram"
            outlined
            v-model="config.instagram_url"
          />
          <v-text-field
            label="WhatsApp"
            outlined
            v-model="config.whatsapp"
            v-mask="['(##) #####-####']"
          />
          <v-text-field label="Endereço" outlined v-model="config.address" />
          <v-textarea
            v-if="$p.name.indexOf('mcn') >= 0"
            label="Textos menu"
            outlined
            v-model="config.menu_texts"
          />
          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Logo"
              outlined
              v-model="picture"
            ></v-file-input>
            <img
              :src="urlImage"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Logo - Escura"
              outlined
              v-model="logoDark"
            ></v-file-input>
            <img
              :src="previewLogoDark"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Logo - Fundo Escuro"
              outlined
              v-model="logoWhite"
            ></v-file-input>
            <img
              :src="previewLogoWhite"
              width="55px"
              height="55px"
              class="ml-5 black"
              style="object-fit: contain"
            />
          </div>
          <div class="d-flex" v-if="$p.name.indexOf('mcn') >= 0">
            <v-switch
              label="Mostrar"
              class="mr-3"
              v-model="banner_lat_active"
            />
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Banner lateral - AO VIVO"
              outlined
              v-model="banner_lat"
            ></v-file-input>
            <img
              :src="urlImageBannerLat"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <div class="d-flex" v-if="$p.name.indexOf('mcn') >= 0">
            <v-switch
              label="Mostrar"
              class="mr-3"
              v-model="banner_inf_active"
            />
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Banner inferior - AO VIVO"
              outlined
              v-model="banner_inf"
            ></v-file-input>
            <img
              :src="urlImageBannerInf"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>

          <h3 class="text-caption">Página Sobre</h3>
          <div
            style="
              border: solid 1px rgba(0, 0, 0, 0.38);
              border-radius: 4px;
              padding: 4px;
            "
          >
            <tiptap-vuetify
              v-model="config.about"
              :extensions="extensions"
              icon="mdi"
              :cardProps="{ elevation: 0 }"
            />
          </div>

          <h3 class="text-caption mt-5">Página de cadastro</h3>
          <div
            style="
              border: solid 1px rgba(0, 0, 0, 0.38);
              border-radius: 4px;
              padding: 4px;
            "
          >
            <tiptap-vuetify
              v-model="config.textRegister"
              :extensions="extensions"
              icon="mdi"
              :cardProps="{ elevation: 0 }"
            />
          </div>
          <hr />
          <h4>Página de contato</h4>
          <v-checkbox
            label="Mostrar formulário"
            v-model="config.contactPageShowForm"
          />
          <v-btn @click="submit" color="primary" class="mt-5">Salvar</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from "tiptap-vuetify";
export default {
  name: "Config",
  components: { TiptapVuetify },
  data: () => ({
    picture: "",
    banner_lat: "",
    banner_inf: "",
    logoDark: "",
    logoWhite: "",
    config: {
      logo: "",
      title: "",
      description: "",
      youtubeChannelId: "",
      youtube_url: "",
      instagram_url: "",
      whatsapp: "",
      address: "",
      menu_texts: "",
      banner_lat: "",
      banner_inf: "",
      about: "",
      textRegister: "",
      logoDark: "",
      logoWhite: "",
      contactPageShowForm: true,
    },
    banner_lat_active: false,
    banner_inf_active: false,

    extensions: [
      Image,
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      Paragraph,
      HardBreak,

      Table,
      TableHeader,
      TableCell,
      TableRow,
    ],
  }),
  methods: {
    sendImage(file, width, height) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("format", "png");
        if (width) formData.append("width", width);
        if (height) formData.append("height", height);
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    async submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });

      const payload = {
        title: this.config.title,
        description: this.config.description,
        youtubeChannelId: this.config.youtubeChannelId,
        youtube_url: this.config.youtube_url,
        instagram_url: this.config.instagram_url,
        whatsapp: this.config.whatsapp,
        address: this.config.address,
        menu_texts: this.config.menu_texts,
        about: this.config.about,
        textRegister: this.config.textRegister,
        contactPageShowForm: this.config.contactPageShowForm,
        logo:
          typeof this.picture === "object"
            ? (await this.sendImage(this.picture)).image
            : this.picture,
        logoDark:
          typeof this.logoDark === "object"
            ? (await this.sendImage(this.logoDark)).image
            : this.logoDark,
        logoWhite:
          typeof this.logoWhite === "object"
            ? (await this.sendImage(this.logoWhite)).image
            : this.logoWhite,
        banner_lat:
          typeof this.banner_lat === "object" && this.banner_lat_active
            ? (await this.sendImage(this.banner_lat, 312, 563)).image
            : this.banner_lat_active
            ? this.banner_lat
            : "",
        banner_inf:
          typeof this.banner_inf === "object" && this.banner_inf_active
            ? (await this.sendImage(this.banner_inf, 1161, 190)).image
            : this.banner_inf_active
            ? this.banner_inf
            : "",
      };

      this.picture = payload.logo;
      this.logoDark = payload.logoDark;
      this.logoWhite = payload.logoWhite;
      this.banner_inf = payload.banner_inf;
      this.banner_lat = payload.banner_lat;

      try {
        await api.patch("/config/set", payload);
        this.$swal("Ótimo!", "Alterações salvas com sucesso!", "success");
        loader.hide();
      } catch (err) {
        console.error(err);
        this.$swal("Ops!", "Ocorreu um problema!", "error");
        loader.hide();
      }
    },

    async get() {
      const { data } = await api.get("/config/get", {
        params: {
          keys: Object.keys(this.config).join(","),
        },
      });
      this.config.title = data.title || "";
      this.picture = data.logo || "";
      this.banner_lat = data.banner_lat || "";
      this.banner_inf = data.banner_inf || "";
      this.banner_lat_active = !!data.banner_lat;
      this.banner_inf_active = !!data.banner_inf;
      this.config.description = data.description || "";
      this.config.youtubeChannelId = data.youtubeChannelId || "";
      this.config.instagram_url = data.instagram_url || "";
      this.config.youtube_url = data.youtube_url || "";
      this.config.whatsapp = data.whatsapp || "";
      this.config.address = data.address || "";
      this.config.menu_texts = data.menu_texts || "";
      this.config.about = data.about;
      this.config.textRegister = data.textRegister || "";
      this.config.contactPageShowForm = data.contactPageShowForm;
      this.logoDark = data.logoDark || "";
      this.logoWhite = data.logoWhite || "";
    },
    async init() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    urlImage() {
      if (!this.picture) return;
      if (typeof this.picture === "string") return this.picture;
      return URL.createObjectURL(this.picture);
    },
    urlImageBannerLat() {
      if (!this.banner_lat) return;
      if (typeof this.banner_lat === "string") return this.banner_lat;
      return URL.createObjectURL(this.banner_lat);
    },
    urlImageBannerInf() {
      if (!this.banner_inf) return;
      if (typeof this.banner_inf === "string") return this.banner_inf;
      return URL.createObjectURL(this.banner_inf);
    },
    previewLogoDark() {
      if (!this.logoDark) return;
      if (typeof this.logoDark === "string") return this.logoDark;
      return URL.createObjectURL(this.logoDark);
    },
    previewLogoWhite() {
      if (!this.logoWhite) return;
      if (typeof this.logoWhite === "string") return this.logoWhite;
      return URL.createObjectURL(this.logoWhite);
    },
  },
};
</script>

<style></style>
