<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <h3 class="text-h3">Usuários</h3>
        <p>Usuários que gerenciam a plataforma</p>
      </div>
      <v-btn color="primary" to="/criar-usuario">
        <v-icon>mdi-plus</v-icon>
        <span>novo</span>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="primary" :to="`/editar-usuario/${item._id}`">
          <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn
          icon
          color="error"
          @click="() => deleteRow(item._id)"
          v-if="item._id !== $store.state.user._id"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "Users",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Último login", value: "last_login" },
      { text: "Ações", value: "actions", align: "end" },
    ],
    users: [],
  }),

  created() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      api
        .get("/user")
        .then(
          ({ data }) =>
            (this.users = data.map((a) => ({
              ...a,
              last_login: moment().format("DD/MM/YYYY HH:mm:ss"),
            })))
        )
        .catch((e) => {
          this.$swal(
            "Ops...",
            "Ocorreu um problema ao buscar os usuários.",
            "error"
          );
          this.$router.push("/");
        });
    },
    deleteRow(id) {
      this.$swal({
        title: "Tem certeza?",
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Desistir`,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.users = this.users.filter((a) => a._id !== id);
          api.delete("/user/" + id);
        }
      });
    },
  },
};
</script>

<style></style>
