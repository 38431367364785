import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    SET_USER: (state, payload) => (state.user = payload),
  },
  actions: {
    async fetchUser({ state, commit }) {
      if (!localStorage.tk) return;
      if (state.user) return state.user;

      try {
        const { data } = await api.get("/auth/me");
        commit("SET_USER", data);
        return true;
      } catch {
        return false;
      }
    },
  },
  modules: {},
});
