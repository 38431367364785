<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <h3 class="text-h3">Eventos</h3>
        <p>Agenda de eventos</p>
      </div>
      <v-btn color="primary" to="/criar-evento">
        <v-icon>mdi-plus</v-icon>
        <span>novo</span>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="events"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div class="ma-5">
          <v-img :aspect-ratio="4 / 3" :src="item.image" />
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <b> {{ item.scheduled ? "AGENDAMENTO |" : "" }} </b>
        {{ item.name }}
      </template>
      <template v-slot:[`item.show`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="() => toggleHidden(item._id)"
              icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{
                item.hidden ? "mdi-eye-off-outline" : "mdi-eye"
              }}</v-icon>
            </v-btn>
          </template>
          <span>Clique para {{ item.hidden ? "mostrar" : "ocultar" }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          color="primary"
          :to="`/editar-evento/${item.scheduled || item._id}`"
        >
          <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="() => deleteRow(item._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tooltip bottom v-if="$p.askLeadToDownload">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="success"
              @click="() => showDownloads(item.downloads)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-list-status</v-icon>
            </v-btn>
          </template>
          <span>Downloads</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="modalDownloads" max-width="600px">
      <v-card>
        <v-card-title>Lista de Downloads</v-card-title>
        <v-card-text>
          <table
            style="width: 100%"
            class="text-center"
            v-if="downloads && downloads.length > 0"
          >
            <tr>
              <th>E-mail</th>
              <th>Telefone</th>
            </tr>
            <tr v-for="(item, i) in downloads" :key="i">
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
            </tr>
          </table>
          <p
            class="text-center my-12"
            v-if="!downloads || downloads.length === 0"
          >
            Vazio
          </p>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn text @click="() => (modalDownloads = false)">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "Events",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Local", value: "location" },
      { text: "Imagem", value: "image" },
      { text: "Data", value: "date" },
      { text: "Exibir", value: "show" },
      { text: "Ações", value: "actions", align: "end" },
    ],
    events: [],

    downloads: [],
    modalDownloads: false,
  }),

  created() {
    this.get();
  },

  methods: {
    showDownloads(downloads) {
      this.downloads = downloads;
      this.modalDownloads = true;
    },
    get() {
      api
        .get("/auction")
        .then(
          ({ data }) =>
            (this.events = data.map((a) => ({
              ...a,
              date: a.date
                ? moment(a.date).utcOffset(a.date).format("DD/MM/YYYY")
                : "-",
              created_at: moment().format("DD/MM/YYYY HH:mm:ss"),
            })))
        )
        .catch((e) => {
          console.error(e);
          this.$swal(
            "Ops...",
            "Ocorreu um problema ao buscar os dados.",
            "error"
          );
          this.$router.push("/");
        });
    },
    deleteRow(id) {
      this.$swal({
        title: "Tem certeza?",
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Desistir`,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.events = this.events.filter(
            (a) => a._id !== id && a.scheduled !== id
          );
          api.delete("/auction/" + id);
        }
      });
    },
    toggleHidden(id) {
      const index = this.events.findIndex((a) => a._id === id);
      this.events[index].hidden = !this.events[index].hidden;

      api.post(
        "/auction/" + id + (this.events[index].hidden ? "/hide" : "/show")
      );
    },
  },
};
</script>

<style></style>
