<template>
  <v-card :color="color" dark>
    <div
      class="
        d-flex
        flex-column flex-md-row flex-no-wrap
        justify-space-between
        align-center
        pt-3
        pb-3
      "
    >
      <div>
        <v-card-title class="text-h5" v-text="title" />

        <v-card-subtitle v-text="subtitle" />
      </div>

      <div class="d-flex align-center">
        <v-icon size="70">{{ icon }}</v-icon>
        <span class="text-h2 mr-5" v-text="value" />
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardDashboard",
  props: ["title", "subtitle", "icon", "color", "value"],
};
</script>

<style></style>
