<template>
  <div>
    <h3 class="text-h3">Carousel</h3>
    <v-card class="mt-4">
      <v-card-text>
        <form @submit.prevent="submit">
          <v-text-field label="Título" outlined v-model="name" />
          <v-text-field
            label="Link de redirecionamento"
            outlined
            v-model="url"
          />
          <div class="d-flex">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Escolha a imagem"
              prepend-icon="mdi-camera"
              label="Banner"
              outlined
              v-model="picture"
            ></v-file-input>
            <img
              :src="urlImage"
              width="55px"
              height="55px"
              class="ml-5"
              style="object-fit: contain"
            />
          </div>
          <v-btn @click="submit" color="primary">Salvar</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "CarouselsForm",
  data: () => ({
    name: "",
    url: "",
    picture: "",
  }),
  methods: {
    sendImage(file) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("width", this.$p.carousel.size[0]);
        formData.append("height", this.$p.carousel.size[1]);
        formData.append("thumb", "0.08");
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    validate() {
      if (!this.name || !this.picture) {
        this.$swal("Ops..", "Informe todos os dados.", "error");
      } else return true;
    },
    async submit() {
      if (!this.validate()) return;
      const payload = {
        name: this.name,
        url: this.url,
      };

      const loader = this.$loading.show({
        // Optional parameters
        color: this.$vuetify.theme.themes.light.primary,
      });

      if (typeof this.picture === "object") {
        const upload = await this.sendImage(this.picture);
        payload.image = upload.image;
        payload.thumb = upload.thumb;
      }

      try {
        const {
          data: { _id },
        } = await api[this.isEdit ? "patch" : "post"](
          "/carousel/" + (this.$route.params.id || ""),
          payload
        );
        if (!this.isEdit) this.$router.push("/editar-carousel/" + _id);
        this.$swal("Ótimo!", "Alterações salvas com sucesso!", "success");
        loader.hide();
      } catch (err) {
        console.error(err);
        this.$swal("Ops!", "Ocorreu um problema!", "error");
        loader.hide();
      }
    },

    async get(id) {
      const { data } = await api.get("/carousel/" + id);
      this.name = data.name;
      this.url = data.url;
      this.picture = data.image;
    },
    async init() {
      if (this.isEdit) this.get(this.$route.params.id);
      else {
        this.name = "";
        this.url = "";
        this.picture = "";
      }
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    urlImage() {
      if (!this.picture) return;
      if (typeof this.picture === "string") return this.picture;
      return URL.createObjectURL(this.picture);
    },
    isEdit() {
      return this.$route.name.indexOf("Editar") > -1;
    },
  },
};
</script>

<style></style>
