<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <h3 class="text-h3">Parceiros</h3>
        <p>Sessão de parceiros tela inicial</p>
      </div>
      <v-btn color="primary" to="/criar-parceiro">
        <v-icon>mdi-plus</v-icon>
        <span>novo</span>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="partners"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div class="ma-5">
          <v-img contain :aspect-ratio="1366 / 450" :src="item.image" />
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="primary" :to="`/editar-parceiro/${item._id}`">
          <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="() => deleteRow(item._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "Partners",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Imagem", value: "image" },
      { text: "Redirecionamento", value: "url" },
      { text: "Ações", value: "actions", align: "end" },
    ],
    partners: [],
  }),

  created() {
    this.get();
  },

  methods: {
    get() {
      api
        .get("/partner")
        .then(({ data }) => (this.partners = data))
        .catch((e) => {
          this.$swal(
            "Ops...",
            "Ocorreu um problema ao buscar os dados.",
            "error"
          );
          this.$router.push("/");
        });
    },
    deleteRow(id) {
      this.$swal({
        title: "Tem certeza?",
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Desistir`,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.partners = this.partners.filter((a) => a._id !== id);
          api.delete("/partner/" + id);
        }
      });
    },
  },
};
</script>

<style></style>
