<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <h3 class="text-h3">Cadastros</h3>
        <img :src="$p.logo" id="logo" style="display: none" />
        <p>Clientes que realizaram cadastro na plataformas</p>
      </div>
      <iframe style="display: none"> </iframe>
      <div>
        <v-btn color="success" @click="downloadCsv">
          <v-icon>mdi-microsoft-excel </v-icon>
          <span>Baixar planilha</span>
        </v-btn>
      </div>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="clients"
      :items-per-page="5"
      class="elevation-1"
      show-select
      item-key="_id"
      search
    >
      <template v-slot:[`item.image`]="{ item }">
        <div class="ma-5">
          <v-img :aspect-ratio="1" :src="item.image" />
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status === 'ACCEPTED' ? 'success' : 'warning'">{{
          item.status === "ACCEPTED" ? "APROVADO" : "AGUARDANDO"
        }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          text
          :color="item.status === 'ACCEPTED' ? 'warning' : 'success'"
          @click="
            () =>
              setStatus(
                item._id,
                item.status === 'ACCEPTED' ? 'NOT ACCEPTED' : 'ACCEPTED'
              )
          "
        >
          <v-icon>{{
            item.status === "ACCEPTED"
              ? "mdi-account-cancel"
              : "mdi-account-check"
          }}</v-icon>
          <span class="ml-1">{{
            item.status === "ACCEPTED" ? "DESATIVAR" : "APROVAR"
          }}</span>
        </v-btn>
        <v-btn icon color="error" @click="() => deleteRow(item._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon color="info" @click="() => editRow(item._id)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="success" @click="() => printRow(item._id)">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "clients",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Cidade", value: "city" },
      { text: "E-mail", value: "email" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "actions", align: "end" },
    ],
    clients: [],
    selected: [],
  }),

  created() {
    this.get();
  },

  methods: {
    get() {
      api
        .get("/client")
        .then(({ data }) => (this.clients = data))
        .catch((e) => {
          this.$swal(
            "Ops...",
            "Ocorreu um problema ao buscar os dados.",
            "error"
          );
          this.$router.push("/");
        });
    },
    deleteRow(id) {
      this.$swal({
        title: "Tem certeza?",
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Desistir`,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.clients = this.clients.filter((a) => a._id !== id);
          api.delete("/client/" + id);
        }
      });
    },
    printRow(id) {
      /*const loader = this.$loading.show({
        // Optional parameters
        color: this.$vuetify.theme.themes.light.primary,
      });*/

      const row = this.clients.find((a) => a._id === id);

      const data = {
        "Dados pessoais": {
          "Nome Completo": row.name,
          Nascimento: row.birth_date,
          "CPF/CNPJ": row.document_number,
        },
        "Dados de correspondência": {
          CEP: row.address.zip_code,
          Endereço: row.address.address,
          Número: row.address.number,
          Bairro: row.address.district,
          Cidade: row.address.city + " - " + row.address.uf,
        },
        "Dados de contato": {
          "E-mail": row.email,
          Telefone: row.phone,
          Celular: row.phone2,
        },
        "Dados de propriedade": row.property
          ? {
              Fazenda: row.property.name,
              "Inscr. Produtor": row.property.number,
              Endereço: row.property.address,
              Cidade: row.property.city + " - " + row.property.uf,
            }
          : null,
        "Dados Bancários": row.bank
          ? {
              Banco: row.bank.bank,
              "Tipo de conta": row.bank.bank_type,
              Agência: row.bank.agency,
              Conta: row.bank.number + "-" + row.bank.digit,
              Telefone: row.bank.phone,
              Gerente: row.bank.manager,
            }
          : null,
        Preferências: row.racas,
        Referencias: row.person_references,
      };

      const value = {
        string: (a) => a,
        object: (a) =>
          a[0]
            ? a
                .map((a) =>
                  Object.values(a)
                    .slice(1)
                    .filter((a) => a)
                    .join("/")
                )
                .join("<br />")
            : Object.entries(a)
                .map((a) => "<b>" + a[0] + ":</b> " + a[1])
                .join("<br />"),
      };

      console.log(data);

      const content =
        `<div style="text-align: center">\
          <img style="max-width: 300px;" src="${
            document.querySelector("#logo").src
          }" /><h1>Ficha de cadastro</h1><div style="   -webkit-column-count: 2;
      -moz-column-count: 2;
           column-count: 2;

   -webkit-column-gap: 20px;
      -moz-column-gap: 20px;
           column-gap: 20px;">` +
        Object.entries(data)
          .filter((a) => a && a[1])
          .map((a) => "<h3>" + a[0] + "</h3>" + value[typeof a[1]](a[1]))
          .join("<br /><br />") +
        "</div></div>";

      const iframe = document.querySelector("iframe").contentWindow;
      if (!iframe) return;
      iframe.document.body.innerHTML = content;
      iframe.print();
    },
    downloadCsv() {
      if (this.selected.length === 0)
        return this.$swal(
          "Ops..",
          "Selecione no minimo um cliente para exportar",
          "warning"
        );

      const html =
        "<table><tr>" +
        [
          `<th>Nome</th><th>E-mail</th><th>Cidade</th><th>Telefone 1</th><th>Telefone 2</th>`,
          ...this.selected.map(
            (item) =>
              `<td>${[
                item.name,
                item.email,
                [item.address.city, item.address.uf].join(" - "),
                item.phone,
                item.phone2,
              ].join("</td><td>")}</td>`
          ),
        ].join("</tr><tr>") +
        "</tr></table>";

      const base64 = (s) => {
        return window.btoa(unescape(encodeURIComponent(s)));
      };

      const link = document.createElement("a");
      link.download = `cadastros_${this.selected.length}_${moment().format(
        "YYYY-MM-DD"
      )}.xls`;
      link.href = "data:application/vnd.ms-excel;base64," + base64(html);
      link.click();
    },
    setStatus(id, status) {
      api.post("/client/status/" + id, { status });
      this.clients = this.clients.map((a) =>
        a._id === id ? { ...a, status } : a
      );
    },
    editRow(id) {
      const row = this.clients.find((a) => a._id === id);
      window.open(
        this.$p.websiteUrl + "/cadastro?data=" + encodeURI(JSON.stringify(row)),
        "_blank"
      );
    },
  },
  mounted() {
    const iframe = document.querySelector("iframe").contentWindow;
    if (!iframe) return;

    iframe.document.body.innerHTML = `<img style="max-width: 300px;" src="${
      document.querySelector("#logo").src
    }" />`;
  },
};
</script>

<style></style>
