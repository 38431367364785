<template>
  <v-card>
    <v-card-title>Histórico de lances</v-card-title>
    <v-list-item two-line v-for="(bid, i) in history" :key="i">
      <v-list-item-avatar>
        <v-img
          :src="bid.client.picture || 'https://i.ibb.co/2MkGB09/image-221.png'"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ bid.client.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <b>LOTE {{ bid.lot }}</b> | {{ bid.at | date }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        {{ bid.bid | brl }}
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "HistoryCard",
  data: () => ({
    history: [],
  }),

  props: ["socket"],

  mounted() {
    this.socket.on("history", (data) => {
      this.history = data;
    });
  },
  filters: {
    date(v) {
      return moment(v).format("DD/MM/YYYY HH:mm:ss");
    },
    brl(val) {
      if (!val && val !== 0) return null;
      return "R$" + Math.round(val - 0); // .replace('.', ',')
    },
  },
};
</script>

<style></style>
