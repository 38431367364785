import Vue from "vue";
import api from "@/api";

const projects = {
  sindpatos: {
    hostkey: "patos",
    name: "sindpatos",
    logo: "https://i.ibb.co/g3WMv5L/logo-sindpatos.png",
    whiteLogo: "https://i.ibb.co/9svf1sS/logo-white-sindpatos.png",
    title: "Sindicato de Patos",
    color: "#0B4841",
    modules: [
      "users",
      "carousel",
      "partners",
      "clients",
      "contact",
      "content-home",
    ],
    carousel: { size: [1476, 728] },
  },
  canalmcn: {
    hostkey: "canalmcn",
    name: "canalmcn",
    logo: "https://i.ibb.co/f2Jhd7P/image-12-Traced-1.png",
    whiteLogo: "https://i.ibb.co/CmCk3Bf/image-12-Traced.png",
    title: "Canal MCN",
    color: "#133A8D",
    modules: [
      "users",
      "carousel",
      "partners",
      "contact",
      "content-home",
      "events",
    ],
    carousel: { size: [1366, 450] },
  },
  mcn: {
    hostkey: "mcn",
    name: "mcn",
    logo: "https://i.ibb.co/9nMbbNP/logo-mcn.png",
    whiteLogo: "https://i.ibb.co/Pxc3jPg/logo-white-mcn.png",
    title: "MCN Leilões",
    color: "#1976D2",
    modules: [
      "users",
      "carousel",
      "partners",
      "clients",
      "contact",
      "content-home",
      "events",
    ],
    carousel: { size: [1366, 450] },
  },
  rubinho: {
    hostkey: "rubinho",
    name: "rubinho",
    logo: "	https://pkhadson.s3.sa-east-1.amazonaws.com/d0a540a2-12f1-42d1-ac4e-48717bf6b360.png",
    whiteLogo:
      "	https://pkhadson.s3.sa-east-1.amazonaws.com/d0a540a2-12f1-42d1-ac4e-48717bf6b360.png",
    title: "Rubinho Leiloeiro",
    color: "#3a2821",
    modules: [
      "painel-stream",
      "painel-lance",
      "users",
      "carousel",
      "partners",
      "clients",
      "contact",
      "content-home",
      "events",
    ],
    carousel: { size: [1366, 450] },
  },
  leilooa: {
    hostkey: "leilooa",
    name: "leilooa",
    logo: "https://pkhadson.s3.sa-east-1.amazonaws.com/Group+91.png",
    whiteLogo:
      "https://leilooa.com.br/assets/img/app-landing-2/logo.png",
    title: "Leilooa - DEMO",
    color: "#02A9A2",
    modules: [
      "painel-stream",
      "painel-lance",
      "users",
      "carousel",
      "partners",
      "clients",
      "contact",
      "content-home",
      "events",
    ],
    carousel: { size: [1366, 450] },
  },
};

Vue.prototype.$p = Object.values(projects).find((a) =>
  window.location.host.includes(a.hostkey)
);

api
  .get("/config/get", {
    params: { keys: ["websiteUrl", "askLeadToDownload"].join(",") },
    project: Vue.prototype.$p.name,
  })
  .then(({ data }) => {
    Vue.prototype.$p = { ...Vue.prototype.$p, ...data };
  });

export default {
  install: () => {
    console.log("PROJETO");
  },
};
