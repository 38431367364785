<template>
  <div>
    <v-card
      color="primary"
      dark
      class="mb-4"
      v-for="(bid, i) in items"
      :key="i"
    >
      <v-card-title class="d-flex justify-space-between">
        <span>Lance pendente</span>
        <h2>+{{ bid.bid | brl }}</h2>
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img
                :src="
                  bid.client.picture || 'https://i.ibb.co/2MkGB09/image-221.png'
                "
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                bid.client.name.substr(0, 18)
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <b>LOTE {{ bid.lot }}</b> | {{ bid.at | date }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div class="d-flex">
                <v-btn
                  icon
                  color="success"
                  class="mr-5"
                  @click="() => accept(i)"
                >
                  <v-icon>mdi-thumb-up</v-icon>
                </v-btn>
                <v-btn icon color="error" @click="() => decline(i)">
                  <v-icon>mdi-thumb-down</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "PendingCard",
  data: () => ({
    items: [],
  }),
  props: ["socket"],
  mounted() {
    this.socket.on("request_bid", (data) => {
      this.items.push(data);
    });
    this.$root.$on("clearPending", () => (this.items = []));
  },
  methods: {
    accept(index) {
      const bid = this.items[index];
      this.socket.emit("approve_bid", bid);
      this.items = this.items.filter((_a, i) => i != index);
    },
    decline(index) {
      this.items = this.items.filter((_a, i) => i != index);
    }
  },
  filters: {
    date(v) {
      return moment(v).format("DD/MM/YYYY HH:mm:ss");
    },
    brl(val) {
      if (!val && val !== 0) return null;
      return "R$" + Math.round(val - 0); // .replace('.', ',')
    },
  },
};
</script>

<style></style>
