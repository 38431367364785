<template>
  <v-dialog max-width="600px" v-model="open">
    <v-card class="lot-edit">
      <v-card-title>Cadastro de lotes</v-card-title>
      <v-card-text>
        <v-text-field
          class="mt-4"
          label="Número do lote"
          outlined
          v-model="currentLot.lot"
        />
        <h3 class="text-caption">Descrição</h3>
        <div
          class="mb-8"
          style="
            border: solid 1px rgba(0, 0, 0, 0.38);
            border-radius: 4px;
            padding: 4px;
          "
        >
          <tiptap-vuetify
            v-model="currentLot.description"
            :extensions="extensions"
            icon="mdi"
            :cardProps="{ elevation: 0 }"
          />
        </div>
        <v-text-field label="Fazenda" outlined v-model="currentLot.location" />

        <div
          v-for="(media, i) in currentLot.media"
          :key="i"
          class="d-flex mb-7"
        >
          <div style="max-width: 100px">
            <v-select
              :items="['Video', 'Foto']"
              outlined
              v-model="currentLot.media[i].type"
              hide-details
              label="Tipo"
              dense
            />
          </div>
          <v-text-field
            v-if="currentLot.media[i].type === 'Video'"
            v-model="currentLot.media[i].value"
            label="Cód."
            prefix="youtube.com/watch?v="
            outlined
            class="mx-4"
            hide-details
            dense
          />
          <v-file-input
            accept="image/png, image/jpeg"
            v-model="currentLot.media[i].value"
            v-else
            class="mr-4 ml-2"
            outlined
            dense
            hide-details
            @change="() => createPreview(i)"
          />
          <div style="width: 80px; height: 45px">
            <v-img
              :src="
                currentLot.media[i].type === 'Foto'
                  ? currentLot.media[i].value
                  : `https://img.youtube.com/vi/${currentLot.media[i].value}/0.jpg`
              "
              width="100%"
              height="100%"
            />
          </div>
          <div class="d-flex align-center">
            <v-btn icon color="error" @click="() => deleteMedia(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn @click="addMedia">
          <v-icon>mdi-plus</v-icon>
          <span>Inserir</span>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="open = false">Fechar</v-btn>
        <v-btn color="primary" @click="submit">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  CodeBlock,
} from "tiptap-vuetify";
import api from "@/api";

export default {
  name: "LotEdit",
  components: { TiptapVuetify },
  data: () => ({
    open: false,
    currentLot: {
      lot: "",
      description: "",
      location: "",
      media: [],
    },

    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      CodeBlock,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  methods: {
    deleteMedia(i) {
      this.currentLot.media = this.currentLot.media.filter(
        (_a, ii) => ii !== i
      );
    },
    addMedia() {
      this.currentLot.media.push({ type: "Foto", value: "" });
    },

    createPreview(index) {
      console.log(this.currentLot.media[index].value);
    },

    sendImage(file) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("width", "800");
        formData.append("height", "420");
        formData.append("format", "jpeg");
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },

    async submit() {
      this.open = false;
      const media = [...this.currentLot.media];

      for (let i = 0; i < media.length; i++) {
        const item = media[i];

        if (typeof item.value === "string") media[i] = item.value;
        else
          media[i] =
            console.log(typeof item.value, item) ||
            (await this.sendImage(item.value)).image;
      }

      this.$emit("save", {
        ...this.currentLot,
        media,
      });
    },

    async setLot() {
      const v = this.lot;
      if (!v) return;
      console.log(v);
      this.currentLot = {
        ...v,
        media: v.media.map((a) => ({
          type: a.startsWith("http") ? "Foto" : "Video",
          value: a,
        })),
      };
    },
  },

  mounted() {
    this.setLot();
  },

  props: ["lot"],

  watch: {
    lot(v) {
      console.log(v);
      this.currentLot = {
        ...v,
        media: v.media.map((a) => ({
          type: a.startsWith("http") ? "Foto" : "Video",
          value: a,
        })),
      };
    },
  },
};
</script>

<style lang="scss">
.lot-edit {
  .tiptap-vuetify-editor__content > div {
    min-height: 100px !important;
  }
  .v-text-field__prefix {
    font-size: 13px;
  }
}
</style>
