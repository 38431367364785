











































































import socketio from "socket.io-client";
import api from "@/api";
import { isEqual } from "lodash";

export default {
  name: "ScoreBoard",
  data: () => ({
    scale: [window.innerWidth / 1080, window.innerHeight / 576],
    socket: socketio(
      (api.defaults.baseURL.endsWith("/")
        ? api.defaults.baseURL
        : api.defaults.baseURL + "/") + "main",
      {
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionAttempts: 1000,
      }
    ),

    current: 1740,
    lot: {
      lot: "",
      label: "",
      payment: "",
      details: {
        age: "",
        weight: "",
        location: "",
      },
    },
  }),
  computed: {
    details(): Record<string, string> {
      return {
        Descrição: this.lot.label,
        Idade: this.lot.details.age || "S/I",
      };
    },
  },
  methods: {
    imAdmin() {
      this.socket.emit("data");
      this.socket.emit("imadmin");
    },
  },
  watch: {
    "$vuetify.breakpoint.width"() {
      this.scale = [window.innerWidth / 1080, window.innerHeight / 576];
    },
    "$vuetify.breakpoint.height"() {
      this.scale = [window.innerWidth / 1080, window.innerHeight / 576];
    },
  },
  created() {
    this.socket.on("connect", this.imAdmin);
    this.socket.on("reconnect", this.imAdmin);
    this.socket.on("refresh", (data) => {
      data = JSON.parse(data);
      if (isEqual(this.lot, this.lotEditing))
        this.lotEditing = JSON.parse(JSON.stringify(data.lot));
      this.lot = data.lot;
      this.current = data.current;
      this.currentEditing = 1 - 1 + data.current;
    });
    this.socket.on("listLots", (v) => (this.listLots = v));
  },
  destroyed() {
    this.socket.close();
  },
  filters: {
    brl(v: number) {
      return v.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    },
  },
};
