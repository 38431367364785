var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_vm._m(0),_c('v-btn',{attrs:{"color":"primary","to":"/criar-evento"}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("novo")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":5},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-5"},[_c('v-img',{attrs:{"aspect-ratio":4 / 3,"src":item.image}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(item.scheduled ? "AGENDAMENTO |" : "")+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.show",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","dark":""},on:{"click":function () { return _vm.toggleHidden(item._id); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.hidden ? "mdi-eye-off-outline" : "mdi-eye"))])],1)]}}],null,true)},[_c('span',[_vm._v("Clique para "+_vm._s(item.hidden ? "mostrar" : "ocultar"))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":("/editar-evento/" + (item.scheduled || item._id))}},[_c('v-icon',[_vm._v("mdi-pen")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function () { return _vm.deleteRow(item._id); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1),(_vm.$p.askLeadToDownload)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function () { return _vm.showDownloads(item.downloads); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-list-status")])],1)]}}],null,true)},[_c('span',[_vm._v("Downloads")])]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.modalDownloads),callback:function ($$v) {_vm.modalDownloads=$$v},expression:"modalDownloads"}},[_c('v-card',[_c('v-card-title',[_vm._v("Lista de Downloads")]),_c('v-card-text',[(_vm.downloads && _vm.downloads.length > 0)?_c('table',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('tr',[_c('th',[_vm._v("E-mail")]),_c('th',[_vm._v("Telefone")])]),_vm._l((_vm.downloads),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.phone))])])})],2):_vm._e(),(!_vm.downloads || _vm.downloads.length === 0)?_c('p',{staticClass:"text-center my-12"},[_vm._v(" Vazio ")]):_vm._e()]),_c('v-card-actions',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function () { return (_vm.modalDownloads = false); }}},[_vm._v("Fechar")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-h3"},[_vm._v("Eventos")]),_c('p',[_vm._v("Agenda de eventos")])])}]

export { render, staticRenderFns }