<template>
  <div>
    <h3 class="text-h3">Página: Sobre</h3>
    <v-card class="mt-4">
      <v-card-text>
        <h3 class="text-caption">Página Sobre</h3>
        <div
          style="
            border: solid 1px rgba(0, 0, 0, 0.38);
            border-radius: 4px;
            padding: 4px;
          "
        >
          <tiptap-vuetify
            v-model="about"
            :extensions="extensions"
            icon="mdi"
            :cardProps="{ elevation: 0 }"
          />
        </div>

        <br />

        <v-row
          v-for="(item, i) in gallery"
          :key="i"
          class="d-flex align-center"
        >
          <v-col cols="8">
            <v-file-input v-model="gallery[i].image" label="Imagem" />
          </v-col>
          <v-col cols="2">
            <v-img
              :aspect-ratio="1"
              max-width="100%"
              :src="preview(gallery[i].image)"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              block
              color="error"
              text
              @click="gallery = gallery.filter((_a, ii) => ii !== i)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
              <span>Excluir</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          class="d-table mx-auto my-4"
          @click="() => this.gallery.push({ image: '' })"
        >
          <v-icon>mdi-plus</v-icon>
          <span>Inserir</span>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="submit">
          <v-icon>mdi-content-save-outline</v-icon>
          <span>Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from "tiptap-vuetify";

export default {
  name: "ContentSobre",
  components: { TiptapVuetify },
  data: () => ({
    editing: null,
    gallery: [
      {
        image:
          "https://designshack.net/wp-content/uploads/placeholder-image.png",
      },
    ],
    about: "",
    beneficiosHeader: [
      {
        text: "Icone",
        value: "icon",
        width: "120px",
      },
      { text: "Nome", value: "title" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions", width: "120px" },
    ],

    extensions: [
      Image,
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Table,
      TableHeader,
      TableCell,
      TableRow,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  created() {
    this.get();
  },
  methods: {
    sendImage(file) {
      return new Promise((result) => {
        var formData = new FormData();
        formData.append("width", "1920");
        formData.append("height", "1080");
        formData.append("format", "png");
        formData.append("thumb", ".2");
        formData.append("contain", "true");
        formData.append("file", file);
        api
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => result(data));
      });
    },
    async submit() {
      const loader = this.$loading.show({
        color: this.$vuetify.theme.themes.light.primary,
      });
      try {
        await api.patch("/config/set", {
          gallery: await Promise.all(
            this.gallery
              .filter((a) => a.image)
              .map(async (file) =>
                typeof file.image === "object"
                  ? this.sendImage(file.image)
                  : file
              )
          ),
          about: this.about,
        });

        alert("Dados salvos com sucesso!");
        this.get();
      } catch (err) {
        console.error(err);
      }
      loader.hide();
    },
    async get() {
      const { data } = await api.get("/config/get", {
        params: {
          keys: "gallery,about",
        },
      });
      this.gallery = (data.gallery || []).filter((a) => a.image);
      this.about = data.about;
    },

    preview(file) {
      if (!file) return;
      if (typeof file === "string") return file;
      return URL.createObjectURL(file);
    },
  },
};
</script>

<style></style>
